import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';


interface TAjaxResponseSimple {
    status: string;
    message: string;
}


interface TAjaxResponseUserID {
    status: string;
    message: string;
    userid: string;
}


export class ServiceMail {
    public static async sendMail(): Promise<TAjaxResponseSimple>{
        const response = await axios.post(`${CONFIG_ENV.URL_MAIL.send_mail}`);
        return response.data;
    }

    public static async sendMailNewRequest(id_offer: string): Promise<TAjaxResponseSimple>{
        const response = await axios.post(`${CONFIG_ENV.URL_MAIL.send_mail_new_request}/${id_offer}`);
        return response.data;
    }

    public static async sendMailNewOffer(id_offer: string): Promise<TAjaxResponseSimple>{
        const response = await axios.post(`${CONFIG_ENV.URL_MAIL.send_mail_new_offer}/${id_offer}`);
        return response.data;
    }

    public static async sendMailAcceptOffer(id_offer: string): Promise<TAjaxResponseSimple>{
        const response = await axios.post(`${CONFIG_ENV.URL_MAIL.send_mail_accept_offer}/${id_offer}`);
        return response.data;
    }

    public static async sendMailRefuseOffer(id_offer: string): Promise<TAjaxResponseSimple>{
        const response = await axios.post(`${CONFIG_ENV.URL_MAIL.send_mail_refuse_offer}/${id_offer}`);
        return response.data;
    }

    public static async resendMailModifiedOffer(id_offer: string): Promise<TAjaxResponseSimple>{
        const response = await axios.post(`${CONFIG_ENV.URL_MAIL.resend_mail_modified_offer}/${id_offer}`);
        return response.data;
    }
    
    public static async sendMailContact(mailDetails: string, subject: string): Promise<TAjaxResponseSimple>{
        const params = new URLSearchParams();
        params.set('mailDetails', mailDetails);
        params.set('subject', subject);
        const response = await axios.post(`${CONFIG_ENV.URL_MAIL.send_mail_contact}`, params);
        return response.data;
    }

    public static async sendMailNewUser(userid: string): Promise<TAjaxResponseUserID>{
        const response = await axios.post(`${CONFIG_ENV.URL_MAIL.send_mail_new_user}/${userid}`);
        return response.data;
    }
    
    public static async sendMailModifyOffer(id_offer: string, modifiedOfferText:string): Promise<TAjaxResponseSimple>{
        const params = new URLSearchParams();
        params.set('modifiedOfferText', modifiedOfferText);
        const response = await axios.post(`${CONFIG_ENV.URL_MAIL.resend_mail_modified_offer}/${id_offer}`, params);
        return response.data;
    }
}
