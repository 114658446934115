import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import user from '@/store/user';
import {CONFIG_ENV, URL_API} from '@/config';
import {getModule} from 'vuex-module-decorators';
import {ServiceCompanies} from '@/services/ServiceCompanies'
import {ServiceAdminUsers} from '@/services/ServiceAdminUsers'
import {TNewUserForAdmin} from '@/types/TNewUserForAdmin';
import {TCompany} from '@/types/TCompany';
import nomenclatoare from "@/store/nomenclatoare";
import throttle from 'quasar/src/utils/throttle.js';;
import {ServiceAdminCompanies} from "@/services/ServiceAdminCompanies";
import { TUserType } from '@/types/TUserType';
import { ServiceMail } from '@/services/ServiceMail';
import { TUserForAdmin } from '@/types/TUserForAdmin';
import { TSalesTeams } from '@/types/TSalesTeams';
import { ServiceUser } from '@/services/ServiceUser';

import { ref, computed } from 'vue';

type TNewUserForAdmin2 = TNewUserForAdmin & {team_name:string};

@Options({
    name: "EditNewUser",
    components: {}
})
export default class EditNewUser extends Vue {
    @Prop({ default: '0' }) public readonly companyAppid!: string;
    @Prop({ default: '0' }) public readonly userappid!: string;
    @Prop() public fnOnSaveData!: (userid:string)=>void;
    public ajaxLoadingData=true;
    public optionsCompanies:TCompany[]=[];
    public agents:TUserForAdmin[]=[];
    public user:TNewUserForAdmin2={
        userid: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNr: '',
        functie: '',
        companyCif: '',
        companyName: '',
        agentId:'',
        associated_agent:'',
        id_team:'',
        team_name: ''  
    }
    public urlUserImgProfileByAppid = '';
    declare public $refs: any;
    public userStore = getModule(user);
    public storeNomenclatoare = getModule(nomenclatoare);
    public isEditUser = false;
    public userTeam = '';
    public userType: any;

    get userTypesOptions(): TUserType[] {
        return this.storeNomenclatoare.nomUserTypes;
    }

    get salesTeamsOptions(): TSalesTeams[] {
        return this.storeNomenclatoare.nomSalesTeams;
    }


    public goToView(pRouteViewName: string ) {
        this.$router.push({ name: pRouteViewName })
    }

    public focusOnFirstName(){
        this.$refs.refFirstName.focus();
    }

    public focusOnLastName(){
        this.$refs.refLastName.focus();
    }

    public focusOnEmail(){
        this.$refs.refEmail.focus();
    }

    public focusOnPhoneNr(){
        this.$refs.refPhoneNr.focus();
    }

    public focusOnAgentId(){
        this.$refs.refAgentId.focus();
    }

    public focusOnCompnay(){
        this.$refs.refCompany.focus();
    }

    public getType(val: string){
        switch (val) {
            case "1" : return this.$t('message.admin');
            case "2" : return this.$t('message.agent');
            case "3" : return this.$t('message.agent_sef');
            case "4" : return this.$t('message.client');
        } 
    }

    public onSubmit(){
        const vueInst=this;

        vueInst.$q.loading.show()
        if(!vueInst.isEditUser) {
            ServiceAdminUsers.saveNewUser(vueInst.user).then(response=>{
                vueInst.$q.loading.hide()
                if(response.status=='success'){
                    vueInst.$q.notify({
                        color: 'teal',
                        textColor: 'white',
                        icon: 'positive',
                        position: 'top',
                        timeout: 2000,
                        message: response.message
                    })
                    ServiceMail.sendMailNewUser(response.userid);
                    vueInst.fnOnSaveData(response.userappid);
                }
            }); 
        } else {
            ServiceAdminUsers.editUser(vueInst.user).then(response=>{
                vueInst.$q.loading.hide()
                if(response.status=='success'){
                    vueInst.$q.notify({
                        color: 'teal',
                        textColor: 'white',
                        icon: 'positive',
                        position: 'top',
                        timeout: 2000,
                        message: response.message
                    })
                    // ServiceMail.sendMailNewUser(response.userid);
                    vueInst.fnOnSaveData(response.userappid);
                }
            }); 
        }

    }

    public filterCompanies(val:any, update:any, abort:any) {
        const vueInst=this;
        update(() => {
            const needle = (val?val.toUpperCase():'');
            //this.optionsCompanies = optionsC.filter(item => item.name.toLowerCase().indexOf(needle) > -1)
            vueInst.getFirst20CompaniesByFilter(needle);
        })
    }

    public getCompany(){
        const vueInst=this;
        ServiceAdminCompanies.getCompany(vueInst.companyAppid).then(response=>{
            if(response.status=='success'){
                vueInst.user.companyCif=response.company.cif;
                vueInst.user.companyName=response.company.denumire;
                vueInst.getAgents(response.company.sales_team);
            }
        });
    }

    public getCompanyByCif(cif: string){
        const vueInst=this;
        ServiceAdminCompanies.getCompanyByCif(cif).then(response=>{
            if(response.status=='success'){
                vueInst.user.companyCif=response.company.cif;
                vueInst.user.companyName=response.company.denumire;
                vueInst.getAgents(response.company.sales_team);
            }
        });
    }

    public getAgents(id_team: string){
        const vueInst = this;
        ServiceAdminUsers.getUsersAgents(id_team, '2').then(responce => {
            if(responce.status == 'success'){
                vueInst.agents = responce.users;
            }
        })
    }

    public getAgentsSef(id_team: string){
        const vueInst = this;
        ServiceAdminUsers.getUsersAgents(id_team, '3').then(responce => {
            if(responce.status == 'success'){
                vueInst.agents = responce.users;
            }
        })
        return id_team;
    }

    
    public getTeam(id_team: string){
        const team = this.salesTeamsOptions.find((team) => team.id_team == id_team);
        // console.log(this.salesTeams);
        if(team != undefined) {
            return team.team_name;
        }
        return '';
    }


    public updateTeam(val: string) {
        const vueInst = this;
        vueInst.user.id_team = val;
        vueInst.user.team_name = vueInst.getTeam(val);
        console.log(vueInst.user.id_team);
        
        vueInst.userTeam = vueInst.isEditUser ? vueInst.user.team_name : vueInst.user.id_team;
        if(vueInst.user.functie == '2') {
            vueInst.user.associated_agent= '';
            vueInst.getAgentsSef(vueInst.user.id_team);
        }
        if(vueInst.user.functie == '4') {
            vueInst.user.associated_agent= '';
            vueInst.getAgents(vueInst.user.id_team);
        }

    }

    public combinedLabel = computed(() => {
        return (option: { firstName: string; lastName: string }) => option.firstName == undefined ? '' : `${option.lastName} ${option.firstName}`;
    });

    public updateType(val: string) {
        const vueInst = this;
        vueInst.user.functie = val;
        console.log(vueInst.user.functie);
        vueInst.user.associated_agent= '';
        if(val == '2') {
            vueInst.user.associated_agent= '';
            vueInst.getAgentsSef(vueInst.user.id_team);
        }
        if(val == '4') {
            vueInst.user.associated_agent= '';
            vueInst.getAgents(vueInst.user.id_team);
        }
        
        // vueInst.userType =  vueInst.user.functie;
    }

    @Watch('companyAppid',{ immediate: true})
    onCompanyAppidChanged() {
        console.log('EDITNEWUSER onCompanyAppidChanged')
        const vueInst=this;
        vueInst.getCompany();
    }

    public getFirst20CompaniesByFilter(filterString:string){
        const vueInst=this;
        console.log(vueInst.user.id_team);
        ServiceCompanies.getCompaniesByFilter(filterString, vueInst.user.id_team).then(response=>{
            if(response.status=='success'){
                this.optionsCompanies=response.companies;
            }
        });
    }

    @Watch('user.id_team',{ immediate: true})
    handler(newVal: string) {
        console.log('user.id_team changed:', newVal);
    }

    @Watch('user.functie',{ immediate: true})
    handler2(newVal: string) {
        console.log('user.functie changed:', newVal);
    }


    public onReset(){
        this.user={
            userid: '',
            firstName: '',
            lastName: '',
            functie: '',
            emailAddress: '',
            phoneNr: '',
            companyCif: '',
            companyName: '',
            agentId: '',
            associated_agent: '',
            id_team:'',
            team_name:''
        }
    }

    public created() {
        const vueInst = this;
        console.log('nom user types options ' + vueInst.userappid);
        if(vueInst.userappid != '0') {
            vueInst.isEditUser = true;
            console.log('isEditUser');
            ServiceAdminUsers.getUserDetails2(vueInst.userappid).then(response => {
                if(response.status == 'success'){
                    vueInst.user = response.user;
                    // vueInst.updateType(vueInst.user.functie);
                                
                    vueInst.userTeam = vueInst.isEditUser ? vueInst.user.team_name : vueInst.user.id_team;
                    vueInst.userType = vueInst.isEditUser ? vueInst.getType(vueInst.user.functie) : vueInst.user.functie;
                    // vueInst.updateTeam(vueInst.user.id_team);
                    if(vueInst.user.functie == '4'){
                        // vueInst.getAgents(vueInst.user.id_team);
                        vueInst.getCompanyByCif(vueInst.user.companyCif);
                        vueInst.getFirst20CompaniesByFilter(vueInst.user.companyCif);
                    }
                    if(vueInst.user.functie == '2') {
                        vueInst.getAgentsSef(vueInst.user.id_team);
                    }
                }
            });
        }
        vueInst.userTeam = vueInst.isEditUser ? vueInst.user.team_name : vueInst.user.id_team;
        vueInst.userType = vueInst.isEditUser ? vueInst.getType(vueInst.user.functie) : vueInst.user.functie;
    }
}
