
  
    import { ServiceInvoice } from '@/services/ServiceInvoice';
    import { isSet } from '@vue/shared';
    import {TCertificate} from "@/types/TCertificate";
import VuePdfEmbed from 'vue-pdf-embed';
    export default {
        name:'InvoiceModel',
        props: {
            bill_nr: String
        },
        components:{VuePdfEmbed},
        data: function(){
            return {
                invoice:{
                    id:'',
                    data:'',
                    valoare_eur:0,
                    valoare_huf:0,
                    moneda:'',
                    dataScadenta:'',
                    restDeAchitatEur:0,
                    restDeAchitatHuf:0,
                    nrZileDepasireTermenPlata:''
                },
                isLoadingInvoice: true,
                fixed: false,
                pdfBase64:'',
                areMoreCertificates:false,
                pdfTitle:'',
                popupTitle:'',
                certificates:[],
                selectedBillNr:'',
                widthPdf:300
            }
        },

        methods: {
            getInvoice: function() {
                const vueInst=this;
                console.log(vueInst.bill_nr);
                vueInst.isLoadingInvoice = true;
                ServiceInvoice.getInvoice(vueInst.bill_nr).then( result => {
                    vueInst.isLoadingInvoice = false;
                    if(result.status == 'success') {
                        vueInst.invoice.id = result.invoice.id;
                        vueInst.invoice.data = result.invoice.data;
                        vueInst.invoice.valoare_eur = result.invoice.valoare_eur;
                        vueInst.invoice.valoare_huf = result.invoice.valoare_huf;
                        vueInst.invoice.moneda = result.invoice.moneda;
                        vueInst.invoice.dataScadenta = result.invoice.dataScadenta;
                        vueInst.invoice.restDeAchitatEur = result.invoice.restDeAchitatEur;
                        vueInst.invoice.restDeAchitatHuf = result.invoice.restDeAchitatHuf;
                        vueInst.invoice.nrZileDepasireTermenPlata = result.invoice.nrZileDepasireTermenPlata;
                    }
                })
            },
            download_invoice(bill_nr: string){
                const vueInst=this;
                console.log(bill_nr);
                vueInst.fixed = true;  
                vueInst.selectedBillNr = bill_nr;
                vueInst.pdfBase64 = '';
                vueInst.pdfTitle = '';
                vueInst.popupTitle = this.$t('message.invoice') + ' '+ bill_nr;
                vueInst.isLoadingInvoice = true;      
                ServiceInvoice.downloadInvoice(bill_nr).then(response=>{
                    // vueInst.$q.loading.hide();
                    // vueInst.loadingInvoices=false;
                    
                    vueInst.isLoadingInvoice = false; 
                    if(response.status=='success') { 
                        vueInst.pdfTitle = 'Invoice_'+bill_nr+'.pdf';
                        vueInst.pdfBase64 = 'data:application/pdf;base64,'+ response.message +'#title='+vueInst.pdfTitle;
                    }

                })
            },

            download_certificate(bill_nr: string){
                const vueInst=this;
                console.log(bill_nr);
                vueInst.selectedBillNr = bill_nr;
                vueInst.pdfBase64 = '';
                vueInst.pdfTitle = '';
                vueInst.popupTitle = '';
                vueInst.isLoadingInvoice = true;      
                ServiceInvoice.downloadCertificate(bill_nr).then(response=>{
                    // vueInst.$q.loading.hide();
                    // vueInst.loadingInvoices=false;
                    
                    vueInst.isLoadingInvoice = false; 
                    if(response.status=='success') { 
                        console.log(response);
                        if(response.countCertificates > 0) {
                            vueInst.areMoreCertificates = true;
                            console.log(response.articles);
                            vueInst.certificates = response.articles;
                        } else {  
                            if(isSet(response.message)) {
                                vueInst.fixed = true;  
                                vueInst.pdfTitle = 'Certificate_'+bill_nr+'.pdf';
                                vueInst.pdfBase64 = 'data:application/pdf;base64,'+ response.message +'#title='+vueInst.pdfTitle;
                                vueInst.popupTitle = this.$t('message.certificate') + ' '+bill_nr;
                            } else {
                                vueInst.$q.notify({
                                    color: 'red',
                                    textColor: 'white',
                                    type: 'negative',
                                    icon: 'error',
                                    position: 'top',
                                    timeout: 1000,
                                    message: this.$t('message.no_certificates')
                                })
                            }
                        }
                    }

                })
            },

            download_certificate_2(index: number){
                const vueInst=this;
                const certificat = vueInst.certificates[index];
                vueInst.pdfBase64 = '';
                vueInst.pdfTitle = '';
                vueInst.popupTitle = '';
                vueInst.isLoadingInvoice = true;     
                vueInst.fixed = true;  
                vueInst.pdfTitle = 'Certificate_'+certificat.ItemNo+'.pdf';
                vueInst.pdfBase64 = 'data:application/pdf;base64,'+ certificat.Certificate+'#title='+vueInst.pdfTitle;
                vueInst.popupTitle = this.$t('message.certificate') +' '+certificat.ItemNo;
                vueInst.isLoadingInvoice = false;   
            },

            downloadPDF() {
                const vueInst = this;
                const linkSource = vueInst.pdfBase64;
                const downloadLink = document.createElement("a");
                const fileName = vueInst.pdfTitle;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            },
            changeWitdhPdf(op: string){
                if(op == 'in') {
                    if(this.widthPdf > 1500) {return;}
                    this.widthPdf *= 1.25; 
                } else {
                    if(this.widthPdf < 300) {return;}
                    this.widthPdf /= 1.25; 
                }
            }

        },
        created: function(){
            this.getInvoice();
        }
    }
  