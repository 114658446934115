import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f2fe0f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "dashboard__div__title"
}
const _hoisted_2 = { class: "category__div__content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "subcategories"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HierarchicalChainBrowseHeader = _resolveComponent("HierarchicalChainBrowseHeader")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_CategoryImg = _resolveComponent("CategoryImg")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HierarchicalChainBrowseHeader, {
      hierarchicalChain: _ctx.hierarchicalChain,
      typeOfAccess: _ctx.typeOfAccess,
      pid: _ctx.pid
    }, null, 8, ["hierarchicalChain", "typeOfAccess", "pid"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isMainCategory == true ? 'ecran-container-row' : 'ecran-container')
    }, [
      _createVNode(_component_q_input, {
        outlined: "",
        dense: "",
        modelValue: _ctx.searchItemsText,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchItemsText) = $event)),
          _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems()))
        ],
        label: _ctx.$t('message.search_items'),
        style: _normalizeStyle(_ctx.$q.platform.is.mobile ? 'width: 100%;max-height: 7vh;zoom:0.85;' : 'width:clamp(200px, 40vw, 400px);')
      }, null, 8, ["modelValue", "label", "style"]),
      (_ctx.searchItemsText!='')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            style: _normalizeStyle(_ctx.$q.platform.is.mobile ? 'width: 100%;max-height: 300px' : 'width:clamp(200px, 40vw, 400px);max-height: 300px')
          }, [
            _createVNode(_component_q_table, {
              title: "",
              "rows-per-page-options": [0],
              columns: _ctx.columns,
              "visible-columns": _ctx.visibleColumns,
              rows: _ctx.items,
              "row-key": "name",
              dense: "",
              bordered: "",
              "no-data-label": _ctx.$t('message.no_data'),
              "hide-header": "",
              "hide-bottom": "",
              style: _normalizeStyle(_ctx.$q.platform.is.mobile ? 'width: 100%;max-height: 300px' : 'width:clamp(200px, 40vw, 400px);max-height: 300px')
            }, {
              body: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_td, {
                      key: "name",
                      props: props,
                      style: {"cursor":"pointer"},
                      onClick: ($event: any) => (_ctx.openItem(props.row.pid, props.row.categoryPid, props.row.code))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$i18n.locale == 'ro' ? props.row.name_ro : _ctx.$i18n.locale == 'en' ? props.row.name_en :  props.row.name_hu), 1)
                      ]),
                      _: 2
                    }, 1032, ["props", "onClick"])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 1
            }, 8, ["columns", "visible-columns", "rows", "no-data-label", "style"])
          ], 4))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (mainCateg) => {
        return (_openBlock(), _createElementBlock("div", {
          key: mainCateg.pid,
          class: _normalizeClass({ content__category__with__articles: mainCateg.isParentForArticles=='y'})
        }, [
          (mainCateg.isParentForArticles=='n')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$i18n.locale == 'ro' ? mainCateg.name_ro : _ctx.$i18n.locale == 'en' ? mainCateg.name_en : mainCateg.name_hu), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            (mainCateg.isParentForArticles=='y')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_CategoryImg, {
                    category: mainCateg,
                    onClick: ($event: any) => (_ctx.browseCategory(mainCateg))
                  }, null, 8, ["category", "onClick"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mainCateg.children, (subcategory) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: subcategory.pid
                    }, [
                      _createVNode(_component_CategoryImg, {
                        category: subcategory,
                        onClick: ($event: any) => (_ctx.browseCategory(subcategory))
                      }, null, 8, ["category", "onClick"])
                    ]))
                  }), 128))
                ]))
          ])
        ], 2))
      }), 128))
    ], 2)
  ]))
}