import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import {TUserForAdmin} from '@/types/TUserForAdmin';
import {TNewUserForAdmin} from '@/types/TNewUserForAdmin';

import {getModule} from 'vuex-module-decorators';
import user from '@/store/user';
import {TAdresaLivrare} from "@/types/TAdresaLivrare";


interface TAjaxResponseSimple {
    status: string;
    message: string;
}

interface TAjaxResponseGetUser {
    status: string;
    message: string;
    user:TUserForAdmin
}

interface TAjaxResponseGetUser2 {
    status: string;
    message: string;
    user:TNewUserForAdmin &{team_name:string};
}

interface TAjaxResponseSaveNewUser {
    status: string;
    message: string;
    userappid:string;
    userid:string;
}

interface TAjaxResponseGetUsers {
    status: string;
    message: string;
    users:TUserForAdmin[]
}
export class ServiceAdminUsers {

    public static async resetPassword(puserId: string): Promise<TAjaxResponseSimple> {
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.resetPassword}/${puserId}`);
        return response.data;
    }

    public static async getUsers(companyAppid:string): Promise<TAjaxResponseGetUsers> {
        console.log('getUsers for companyAppid=%o',companyAppid)
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.users}/${companyAppid}`);
        return response.data;
    }

    public static async getUsersBackOffice(filterText:string): Promise<TAjaxResponseGetUsers> {
        const params = new URLSearchParams();
        params.set('filterText', filterText);
        const response = await axios.post(`${CONFIG_ENV.URL_ADMIN.users_back_office}`,params);
        return response.data;
    }

    public static async getUserDetails(puserAppid: string): Promise<TAjaxResponseGetUser> {
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.userDetails}/${puserAppid}`);
        return response.data;
    }

    public static async getUserDetails2(puserAppid: string): Promise<TAjaxResponseGetUser2> {
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.userDetails2}/${puserAppid}`);
        return response.data;
    }

    public static async saveNewUser(puser: TNewUserForAdmin): Promise<TAjaxResponseSaveNewUser> {
        const params = new URLSearchParams();
        params.set('userid', puser.userid);
        params.set('firstName', puser.firstName);
        params.set('lastName', puser.lastName);
        params.set('emailAddress', puser.emailAddress);
        params.set('phoneNr', puser.phoneNr);
        params.set('functie', puser.functie);
        params.set('companyCif', puser.companyCif);
        params.set('agentId', puser.agentId == '' ? 'x' : puser.agentId);
        params.set('associated_agent', puser.associated_agent);
        params.set('id_team', puser.id_team == '' ? '0' : puser.id_team);
        const response = await axios.post(`${CONFIG_ENV.URL_ADMIN.saveNewUser}`,params);
        return response.data;
    }

    public static async editUser(puser: TNewUserForAdmin): Promise<TAjaxResponseSaveNewUser> {
        const params = new URLSearchParams();
        params.set('userid', puser.userid);
        params.set('firstName', puser.firstName);
        params.set('lastName', puser.lastName);
        params.set('emailAddress', puser.emailAddress);
        params.set('phoneNr', puser.phoneNr);
        params.set('functie', puser.functie);
        params.set('companyCif', puser.companyCif);
        params.set('agentId', puser.agentId == '' ? 'x' : puser.agentId);
        params.set('associated_agent', puser.associated_agent);
        params.set('id_team', puser.id_team == '' ? '0' : puser.id_team);
        const response = await axios.post(`${CONFIG_ENV.URL_ADMIN.editUser}`,params);
        return response.data;
    }

    public static async changeStatusUser(userid: string, newStatus:string): Promise<TAjaxResponseSimple> {
        const rnd=Math.random();
        const response = await axios.post(`${CONFIG_ENV.URL_ADMIN.user_status}/${userid}/${newStatus}`);
        return response.data;
    }
    
    public static async getUsersAgents(id_team:string, tip: string): Promise<TAjaxResponseGetUsers> {
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.agents}/${id_team}/${tip}`);
        return response.data;
    }

    public static async deleteUser(userid: string): Promise<TAjaxResponseSimple> {
        const response = await axios.delete(`${CONFIG_ENV.URL_ADMIN.delete_user}/${userid}`);
        return response.data;
    }
}
