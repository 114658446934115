import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import {TUserForAdmin} from '@/types/TUserForAdmin';
import {TAdminCompany} from '@/types/TAdminCompany';
import {TPaginator} from "@/types/TPaginator";
import {getModule} from 'vuex-module-decorators';
import user from '@/store/user';
import {TAdresaLivrare} from "@/types/TAdresaLivrare";



interface TAjaxResponseSimple {
    status: string;
    message: string;
    userappid:string;
}

interface TAjaxResponseGetCompany {
    status: string;
    message: string;
    company:TAdminCompany;
}

interface TAjaxResponseGetCompanyAddress {
    status: string;
    message: string;
    adresa:TAdresaLivrare;
}

interface TAjaxResponseGetCompanies {
    status: string;
    message: string;
    totalPages: number;
    totalItems: number;
    page: TPaginator & {items:TAdminCompany[]};
}
export class ServiceAdminCompanies {

    public static async getCompany(companyAppid:string): Promise<TAjaxResponseGetCompany> {
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.getCompanyByAppid}/${companyAppid}`);
        return response.data;
    }

    public static async getCompanies(pageNumber:number, filterText:string): Promise<TAjaxResponseGetCompanies> {
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.getOnePageCompanies}/${pageNumber}/10`, { params: { filter_text: filterText } });
        return response.data;
    }

    public static async getCompanies2(pageNumber:number, filterText:string, sales_team: string): Promise<TAjaxResponseGetCompanies> {
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.getOnePageCompanies}/${pageNumber}/10/${sales_team}`, { params: { filter_text: filterText } });
        return response.data;
    }

    public static async getUserDetails(puserAppid: string): Promise<TAjaxResponseGetCompanies> {
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.getCompaniesByFilter}/${puserAppid}`);
        return response.data;
    }

    public static async getCompanyByCif(cif:string): Promise<TAjaxResponseGetCompany> {
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.getCompanyByCif}/${cif}`);
        return response.data;
    }

    public static async getCompanyAddress(cif:string): Promise<TAjaxResponseGetCompanyAddress> {
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.getCompanyAddress}/${cif}`);
        return response.data;
    }

    public static async saveNewCompany(pcompany: TAdminCompany, sales_team: string): Promise<TAjaxResponseSimple> {
        const params = new URLSearchParams();
        params.set('cif', pcompany.cif);
        params.set('denumire', pcompany.denumire);
        params.set('rg_jfc', pcompany.rg_jfc);
        // params.set('rg_nr', pcompany.rg_nr);
        // params.set('pf_pj', pcompany.pf_pj);
        // params.set('rg_an', pcompany.rg_an.toString());
        params.set('id_country', pcompany.id_country);
        params.set('sales_team', sales_team);
        const response = await axios.post(`${CONFIG_ENV.URL_ADMIN.saveNewCompany}`,params);
        return response.data;
    }

    public static async changeStatusActivInactiv(cif: string, newStatus:string): Promise<TAjaxResponseSimple> {
        const rnd=Math.random();
        const response = await axios.put(`${CONFIG_ENV.URL_ADMIN.company_status}/${cif}/${newStatus}`);
        return response.data;
    }

    public static async getCompaniesApollo(): Promise<TAjaxResponseGetCompanies> {
        const response = await axios.post(`${CONFIG_ENV.URL_ADMIN.getCompaniesApollo}`);
        return response.data;
    }

    public static async getCompanyApollo(cif:string): Promise<TAjaxResponseSimple> {
        const response = await axios.post(`${CONFIG_ENV.URL_ADMIN.getCompanyApollo}/${cif}`);
        return response.data;
    }
}
