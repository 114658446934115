import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae8bb642"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-top":"1vh"} }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "app__property--medium" }
const _hoisted_4 = { class: "app__property--medium" }
const _hoisted_5 = { style: {"display":"flex","justify-content":"flex-end"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_form, {
        onSubmit: _ctx.onSubmit,
        onReset: _ctx.onReset,
        class: "form__new__user"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_input, {
            outlined: "",
            modelValue: _ctx.company.cif,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.company.cif) = $event)),
            label: _ctx.$t('message.cif'),
            ref: "refCif",
            class: "form__input",
            onKeydown: _withKeys(_withModifiers(_ctx.focusOnCif, ["prevent"]), ["enter"]),
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
          }, null, 8, ["modelValue", "label", "onKeydown", "rules"]),
          _createVNode(_component_q_input, {
            outlined: "",
            ref: "refName",
            modelValue: _ctx.company.denumire,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.company.denumire) = $event)),
            label: _ctx.$t('message.name2'),
            class: "form__input",
            onKeydown: _withKeys(_withModifiers(_ctx.focusOnName, ["prevent"]), ["enter"]),
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
          }, null, 8, ["modelValue", "label", "onKeydown", "rules"]),
          _createVNode(_component_q_input, {
            outlined: "",
            ref: "refCodClient",
            onKeydown: _withKeys(_withModifiers(_ctx.focusOnSalesTeam, ["prevent"]), ["enter"]),
            modelValue: _ctx.company.msz,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.company.msz) = $event)),
            label: _ctx.$t('message.cod_client'),
            class: "form__input",
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
          }, null, 8, ["onKeydown", "modelValue", "label", "rules"]),
          _createVNode(_component_q_select, {
            outlined: "",
            modelValue: _ctx.sales_team,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sales_team) = $event)),
            options: _ctx.salesTeamsOptions,
            label: _ctx.$t('message.sales_team_id'),
            "option-value": "id_team",
            "option-label": "team_name",
            class: "form__input",
            "lazy-rules": "",
            "emit-value": "",
            "map-options": "",
            rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
          }, null, 8, ["modelValue", "options", "label", "rules"]),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('message.reg_com')), 1),
          _createVNode(_component_q_input, {
            outlined: "",
            modelValue: _ctx.company.rg_jfc,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.company.rg_jfc) = $event)),
            label: _ctx.$t('message.jfc'),
            ref: "refJFC",
            class: "form__input2",
            onKeydown: _withKeys(_withModifiers(_ctx.focusOnJFC, ["prevent"]), ["enter"])
          }, null, 8, ["modelValue", "label", "onKeydown"]),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('message.base_address')), 1),
          _createVNode(_component_q_select, {
            outlined: "",
            modelValue: _ctx.adresa.codTara,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.adresa.codTara) = $event)),
            options: _ctx.countryOptions,
            label: _ctx.$t('message.country'),
            "option-value": "code",
            "option-label": _ctx.$i18n.locale == 'ro' ? 'name_ro' : _ctx.$i18n.locale == 'en' ? 'name_en' : 'name_hu',
            class: "form__input",
            "lazy-rules": "",
            "emit-value": "",
            "map-options": "",
            rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
          }, null, 8, ["modelValue", "options", "label", "option-label", "rules"]),
          (_ctx.adresa.codTara == 'RO')
            ? (_openBlock(), _createBlock(_component_q_select, {
                key: 0,
                outlined: "",
                "use-input": "",
                modelValue: _ctx.adresa.adresaCodJudet,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.adresa.adresaCodJudet) = $event)),
                options: _ctx.judeteOptions,
                label: _ctx.$t('message.county'),
                "option-value": "code",
                "option-label": "name",
                class: "form__input",
                "lazy-rules": "",
                "emit-value": "",
                "map-options": ""
              }, null, 8, ["modelValue", "options", "label"]))
            : (_openBlock(), _createBlock(_component_q_input, {
                key: 1,
                outlined: "",
                modelValue: _ctx.adresa.denJudet,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.adresa.denJudet) = $event)),
                label: _ctx.$t('message.county'),
                class: "form__input",
                "lazy-rules": ""
              }, null, 8, ["modelValue", "label"])),
          _createVNode(_component_q_input, {
            outlined: "",
            modelValue: _ctx.adresa.adresaLocalitate,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.adresa.adresaLocalitate) = $event)),
            label: _ctx.$t('message.city'),
            class: "form__input",
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
          }, null, 8, ["modelValue", "label", "rules"]),
          _createVNode(_component_q_input, {
            outlined: "",
            modelValue: _ctx.adresa.adresaAdresa,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.adresa.adresaAdresa) = $event)),
            label: _ctx.$t('message.address'),
            class: "form__input",
            "lazy-rules": "",
            rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
          }, null, 8, ["modelValue", "label", "rules"]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_q_btn, {
              label: _ctx.$t('message.reset'),
              type: "reset",
              color: "primary",
              flat: "",
              class: "q-ml-sm",
              "no-caps": ""
            }, null, 8, ["label"]),
            _createVNode(_component_q_btn, {
              label: _ctx.$t('message.save'),
              type: "submit",
              color: "primary",
              "no-caps": "",
              "text-color": "black"
            }, null, 8, ["label"])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit", "onReset"])
    ])
  ]))
}