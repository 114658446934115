import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import extend from 'quasar/src/utils/extend.js';;
import eventbus from '@/store/eventbus';
import { getModule } from 'vuex-module-decorators';

type infoCateg={
    "categoryPid":string,
    "categoryNameRo":string,
    "categoryNameEn":string,
    "categoryNameHu":string
}


@Options({
    name: "HierarchicalChainBrowseHeader",
    components: {}
})
export default class HierarchicalChainBrowseHeader extends Vue {
    @Prop({ default:() => [] }) public readonly hierarchicalChain!: infoCateg[];
    @Prop({ default: 'fromDashboard' }) public readonly typeOfAccess!: string;
    @Prop({ default: '0' }) public readonly pid!: string;
    public inputFastSearch = '';
    declare public $refs: any;
    public EventBusStore = getModule(eventbus);

    get showBrowseCategoriesIcon():boolean {
        if(this.$route.fullPath=='/browse_categories/0'){
            return false;
        }else return true;
    }

    get showInputFastSearch():boolean {
        /*
        if(this.$route.name=='BrowseArticles' || this.$route.name=='Dashboard'  ||(this.hierarchicalChain && this.hierarchicalChain.length > 0 )) {
            return false;
        }else return true;
         */
        return false;
    }

    public onGoToBrowseCategories(categoryPid:string){
        console.log('hierarchy ' + this.typeOfAccess + ' ' + categoryPid);
        if(this.typeOfAccess == 'fromCerere') {
            this.EventBusStore.set_event({name:'eventBrowseCategories',params:{pid: categoryPid}});
        } else {
            this.$router.push({name: 'BrowseCategories',  params: { pid: categoryPid }});
        }
    }

    activated(): void {
        console.log('activated hierarchy categories', this.hierarchicalChain);
    }

}
