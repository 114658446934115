import {Component, Prop,  Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import {ServiceBrowseArticles} from '@/services/ServiceBrowseArticles';
import {ServiceFavorites} from "@/services/ServiceFavorites";
import {ServiceBasket} from "@/services/ServiceBasket";
import {ServiceProduct} from "@/services/ServiceProduct";
import {getModule} from "vuex-module-decorators";
import user from '@/store/user';
import basket from '@/store/basket';
import favorites from '@/store/favorites';
import nomenclatoare from '@/store/nomenclatoare';
import {getFavorites} from '@/modules/getFavorites'
import {TProductBasket} from '@/types/TProductBasket';
import {TEnumPlacaBara} from '@/types/TEnumPlacaBara';
import HierarchicalChainBrowseHeader from '@/components/HierarchicalChainBrowseHeader/HierarchicalChainBrowseHeader.vue'
import {getBasket} from "@/modules/getBasket";
import {CONFIG_ENV} from "@/config";
import date from 'quasar/src/utils/date.js';import event from 'quasar/src/utils/event.js';;
import position = event.position;
import latinize from 'latinize';
import eventbus from "@/store/eventbus";
import { TArticle } from '@/types/TArticle';
import ServiceLogs from '@/services/ServiceLogs';

type TSize={
    "l":number,
    "w":number,
    "t":number,
    "d":number,
    "h":number,
    "a":string,
    "k":number,
    "g":number,
    "e":number,
    "um1":string,
    "um2":number,
    "um1_to_um2":number,
    "cuDebitare":boolean
}

type TBrowseArticles={
    status: string;
    message: string;
    arrLength: Array<number>;
    arrWidth: Array<number>;
    arrThickness: Array<number>;
    arrDiameter: Array<number>;
    arrHeight:  Array<number>;
    arrAlloy:  Array<string>;
    arrType:  Array<string>;
    arrEyes2: Array<number>;
    categoryNameRo: string;
    categoryNameEn: string;
    categoryNameHu: string;
    categoryPid: number;
    isParentForArticles: string;
    withLength: string;
    withWidth: string;
    withThickness: string;
    withDiameter: string;
    withHeight: string;
    withAlloy: string;
    withEyes: string;
    withEyes2: string;
    positionLength: number;
    positionWidth: number;
    positionThickness: number;
    positionDiameter: number;
    positionHeight: number;
    positionAlloy: number;
    positionEyes: number;
    positionEyes2: number;
    arrSizes:TSize[];
    hierarchicalChain:number[];
}

type TProductBasketMarkedFavorites = TProductBasket & {isInFavorite:boolean};

@Options({
    name: "BrowseArticles",
    components: {HierarchicalChainBrowseHeader}
})
export default class BrowseArticles extends Vue {
    @Prop({ default: '0' }) public readonly pidCategory!: string;
    @Prop({ default: '0' }) public readonly productPid!: string;
    @Prop({ default: '0' }) public readonly code!: string;
    @Prop({ default: 'fromDashboard' }) public readonly typeOfAccess!: string;
    public inputFreeTextComments='';
    public inputCuttingDetails='';
    public nrBucati =0;
    public cuttingLength = 0;
    public cuttingWidth = 0;
    public densitate :number|null = 0;
    public typeOfArticle :TEnumPlacaBara=TEnumPlacaBara.others;
    public qUm1 = 0;
    public qUm2 = 0;
    declare public $refs: any;
    public urlToJPG = CONFIG_ENV.URL_CATEGORY.getJPG;
    public msjErrorRuleCuttingLength = '';
    public msjErrorRuleCuttingWidth = '';
    public msjErrorRuleIntegerNumberBuc = 'Trebuie sa fie multiplu de 6!';
    public dialogTransitionShow='';
    public dialogTransitionHide='';
    public selectedUM1 = true;
    public selectedUM2 = true;
    public tip_um = 'um12';
    public stocArticol = 0;
    public turnover_rate_month = 0;
    public pid='';
    public EventBusStore = getModule(eventbus);
    public categoryUM = 'x';
    public areArticlesWithoutDimensions = false;
    public products: TArticle[] = [];
    public appid = 0;

    public paramArticles: TBrowseArticles={
        status: '',
        message: '',
        arrLength: [],
        arrWidth: [],
        arrThickness:[],
        arrDiameter: [],
        arrHeight: [],
        arrAlloy: [],
        arrType: [],
        arrEyes2: [],
        categoryNameRo: '',
        categoryNameEn: '',
        categoryNameHu: '',
        categoryPid: 0,
        isParentForArticles: '',
        withDiameter: 'n',
        withLength: 'n',
        withThickness: 'n',
        withWidth: 'n',
        withHeight: 'n',
        withAlloy: 'n',
        withEyes: 'n',
        withEyes2: 'n',
        positionLength: 0,
        positionWidth: 0,
        positionThickness: 0,
        positionDiameter: 0,
        positionHeight: 0,
        positionAlloy: 0,
        positionEyes:0,
        positionEyes2:0,
        arrSizes: [],
        hierarchicalChain:[]
    }
    public possibleSizes:TSize[]=[];
    public productCode : string|null = null;
    //productPid
    public selectedLength: number|null = null;
    public selectedWidth: number|null = null;
    public selectedThickness: number|null = null;
    public selectedDiameter: number|null = null;
    public selectedHeight: number|null = null;
    public selectedAlloy: string|null = null;
    public selectedEyes: number|null = null;
    public selectedEyes2: number|null = null;
    public selectedUm1: string|null = null;
    public selectedUm2: string|null = null;
    public selectedUm1ToUm2: number|null = null;
    public isPlacaAluminiu = '0';
    public dorescDebitare = false;
    public loadingStock = false;
    public labelStock = '';
    public loadingPrice = false;
    public labelPrice = '';
    public labelPriceTotal = '';
    public price_huf = 0;
    public price_eur = 0;
    public labelStockColor = '#000000';
    public selectedSize:TSize={
        "l":0,
        "w":0,
        "t":0,
        "d":0,
        "h":0,
        "a":'',
        "k":0,
        "g":0,
        "e":0,
        "um1":'',
        "um2":0,
        "um1_to_um2":1,
        "cuDebitare":false
    }
    public userStore = getModule(user);
    public storeBasket = getModule(basket);
    public storeFavorites = getModule(favorites);
    public storeNomenclatoare = getModule(nomenclatoare);

    
    public columns : any = [{ name: 'name', label: '', field: 'name', align: 'left'}];
    public visibleColumns =['name'];
    public selectedProductCode = '';
    public myPagination:any={rowsPerPage:0}

    private initializeSelectedSize(){
        this.nrBucati=0;
        this.cuttingLength=0;
        this.cuttingWidth=0;
        this.qUm1=0;
        this.qUm2=0;
        this.dorescDebitare=false;
        this.labelPrice='';
        this.labelPriceTotal='';
        this.price_huf = 0;
        this.price_eur = 0;
        this.selectedSize={
            "l":0,
            "w":0,
            "t":0,
            "d":0,
            "h":0,
            "a":'',
            "k":0,
            "g":0,
            "e":0,
            "um1":'',
            "um2":0,
            "um1_to_um2":1,
            "cuDebitare":false
        }
    }

    public selectProduct(productCode: string) {
        const vueInst = this;
        vueInst.selectedProductCode = productCode;
        console.log("set selected product code: " + vueInst.selectedProductCode);
        const selectedElement = document.getElementById(`q-table-row-${vueInst.selectedProductCode}`);
        
        if (selectedElement) {
          selectedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
        vueInst.getUm1Um2Forprodus();
    }

    public toggleArticleInFavorites(){
        const vueInst=this;
        if(!vueInst.articleCouldBePutInFavorites) {
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                icon: 'error',
                position: 'top',
                timeout: 1500,
                message: this.$t('message.check_all_features')
            })
            return false;
        }
        if(vueInst.pidCategory!=vueInst.paramArticles.categoryPid.toString()){
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                icon: 'error',
                position: 'top',
                timeout: 1500,
                message: this.$t('message.errors_app')
            })
        }else{
            vueInst.$q.loading.show();
            ServiceFavorites.identifyArticleInDBAndPutIntoFavorites(vueInst.productCode ? vueInst.productCode : '',vueInst.selectedLength,vueInst.selectedWidth,vueInst.selectedThickness, vueInst.selectedDiameter, vueInst.selectedHeight, vueInst.selectedAlloy, 
                                                                    vueInst.selectedEyes, vueInst.selectedSize.um1, vueInst.selectedSize.um2, vueInst.qUm1, vueInst.qUm2, vueInst.dorescDebitare, vueInst.inputFreeTextComments)
                .then(response=>{
                    if(response.status=='success'){
                        vueInst.$q.loading.hide();
                        getFavorites();
                        vueInst.$q.notify({
                            color: 'red-6',
                            textColor: 'white',
                            icon: 'favorite',
                            position: 'top',
                            timeout: 500,
                            message: this.$t('message.article_added_to_favorite')
                        })
                    }
                }).catch((error) => {
                vueInst.$q.loading.hide();
                vueInst.$q.notify({
                    color: 'red',
                    textColor: 'white',
                    type: 'negative',
                    icon: 'error',
                    position: 'top',
                    timeout: 3000,
                    message: error.toString()
                })
            });

        }
    }

    public setSize(sizeName: string, sizeValue: number|string) {
        // console.log('set size');
        const vueInst = this;
        let isSelectedLengthOk = false;
        let isSelectedWidthOk = false;
        let isSelectedThicknessOk = false;
        let isSelectedDiameterOk = false;
        let isSelectedHeightOk = false;
        let isSelectedAlloyOk = false;
        let isSelectedEyesOk = false;
        let isSelectedEyesOk2 = false;
        vueInst.initializeSelectedSize();
        switch (sizeName) {
            case 'l':
                if (vueInst.selectedLength && vueInst.selectedLength == Number(sizeValue)) {
                    vueInst.selectedLength = null;
                } else {
                    vueInst.selectedLength = Number(sizeValue);
                }
                break;
            case 'w':
                if (vueInst.selectedWidth && vueInst.selectedWidth == Number(sizeValue)) {
                    vueInst.selectedWidth = null;
                } else {
                    vueInst.selectedWidth = Number(sizeValue);
                }
                break;
            case 't':
                if (vueInst.selectedThickness && vueInst.selectedThickness == Number(sizeValue)) {
                    vueInst.selectedThickness = null;
                } else {
                    vueInst.selectedThickness = Number(sizeValue);
                }
                break;
            case 'd':
                if (vueInst.selectedDiameter && vueInst.selectedDiameter == Number(sizeValue)) {
                    vueInst.selectedDiameter = null;
                } else {
                    vueInst.selectedDiameter = Number(sizeValue);
                }
                break;
            case 'h':
                if (vueInst.selectedHeight && vueInst.selectedHeight == Number(sizeValue)) {
                    vueInst.selectedHeight = null;
                } else {
                    vueInst.selectedHeight = Number(sizeValue);
                }
                break;
            case 'a':
                if (vueInst.selectedAlloy && vueInst.selectedAlloy == sizeValue) {
                    vueInst.selectedAlloy = null;
                } else {
                    vueInst.selectedAlloy = sizeValue.toString();
                }
                break;
            case 'k':
                if (vueInst.selectedEyes && vueInst.selectedEyes == sizeValue) {
                    vueInst.selectedEyes = null;
                } else {
                    vueInst.selectedEyes = Number(sizeValue);
                }
                break;
            case 'e':
                if (vueInst.selectedEyes2 && vueInst.selectedEyes2 == sizeValue) {
                    vueInst.selectedEyes2 = null;
                } else {
                    vueInst.selectedEyes2 = Number(sizeValue);
                }
                break;
        }
        vueInst.possibleSizes = JSON.parse(JSON.stringify(vueInst.paramArticles.arrSizes));
        if (vueInst.selectedLength) {
            vueInst.possibleSizes = vueInst.possibleSizes.filter(size => {
                return size.l == vueInst.selectedLength;
            })
        }
        if (vueInst.selectedWidth) {
            vueInst.possibleSizes = vueInst.possibleSizes.filter(size => {
                return size.w == vueInst.selectedWidth;
            })
        }
        if (vueInst.selectedThickness) {
            vueInst.possibleSizes = vueInst.possibleSizes.filter(size => {
                return size.t == vueInst.selectedThickness;
            })
        }

        if (vueInst.selectedDiameter) {
            vueInst.possibleSizes = vueInst.possibleSizes.filter(size => {
                return size.d == vueInst.selectedDiameter;
            })
        }

        if (vueInst.selectedHeight) {
            vueInst.possibleSizes = vueInst.possibleSizes.filter(size => {
                return size.h == vueInst.selectedHeight;
            })
        }

        if (vueInst.selectedAlloy) {
            vueInst.possibleSizes = vueInst.possibleSizes.filter(size => {
                return size.a == vueInst.selectedAlloy;
            })
        }

        if (vueInst.selectedEyes) {
            vueInst.possibleSizes = vueInst.possibleSizes.filter(size => {
                return size.k == vueInst.selectedEyes;
            })
        }

        if (vueInst.selectedEyes2) {
            vueInst.possibleSizes = vueInst.possibleSizes.filter(size => {
                return size.e == vueInst.selectedEyes2;
            })
        }

        vueInst.possibleSizes.forEach(size => {
            if (vueInst.paramArticles.withLength == 'y') {
                if (size.l == vueInst.selectedLength) {
                    isSelectedLengthOk = true;
                }
            }
            if (vueInst.paramArticles.withWidth == 'y') {
                if (size.w == vueInst.selectedWidth) {
                    isSelectedWidthOk = true;
                }
            }
            if (vueInst.paramArticles.withThickness == 'y') {
                if (size.t == vueInst.selectedThickness) {
                    isSelectedThicknessOk = true;
                }
            }
            if (vueInst.paramArticles.withDiameter == 'y') {
                if (size.d == vueInst.selectedDiameter) {
                    isSelectedDiameterOk = true;
                }
            }
            if (vueInst.paramArticles.withHeight == 'y') {
                if (size.h == vueInst.selectedHeight) {
                    isSelectedHeightOk = true;
                }
            }
            if (vueInst.paramArticles.withAlloy == 'y') {
                if (size.a == vueInst.selectedAlloy) {
                    isSelectedAlloyOk = true;
                }
            }
            if (vueInst.paramArticles.withEyes == 'y') {
                if (size.k == vueInst.selectedEyes) {
                    isSelectedEyesOk = true;
                }
            }
            if (vueInst.paramArticles.withEyes2 == 'y') {
                if (size.e == vueInst.selectedEyes2) {
                    isSelectedEyesOk2 = true;
                }
            }
        })
        if (!isSelectedLengthOk) {
            vueInst.selectedLength = null
        }
        if (!isSelectedWidthOk) {
            vueInst.selectedWidth = null
        }
        if (!isSelectedThicknessOk) {
            vueInst.selectedThickness = null
        }
        if (!isSelectedDiameterOk) {
            vueInst.selectedDiameter = null
        }
        if (!isSelectedHeightOk) {
            vueInst.selectedHeight = null
        }
        if (!isSelectedAlloyOk) {
            vueInst.selectedAlloy = null
        }
        if (!isSelectedEyesOk) {
            vueInst.selectedEyes = null
        }
        if (!isSelectedEyesOk2) {
            vueInst.selectedEyes2 = null
        }
        const sizeSelected = vueInst.paramArticles.arrSizes.find((size: TSize) => {
            if (((size.a && size.a == vueInst.selectedAlloy) || !size.a)
                && ((size.d && size.d == vueInst.selectedDiameter) || !size.d)
                && ((size.h && size.h == vueInst.selectedHeight) || !size.h)
                && ((size.k && size.k == vueInst.selectedEyes) || !size.k)
                && ((size.l && size.l == vueInst.selectedLength) || !size.l)
                && ((size.t && size.t == vueInst.selectedThickness) || !size.t)
                && ((size.w && size.w == vueInst.selectedWidth) || !size.w)
                && ((size.e && size.e == vueInst.selectedEyes2) || !size.e)
            ) {
                return true;
            }
        })
        if(sizeSelected){//vueInst.selectedSize=sizeSelected //asta a fost inainte de sa trag um la fiecare produs
            vueInst.selectedSize.um1='';
            vueInst.selectedSize.um2=0;
            vueInst.selectedSize.um1_to_um2=1;
            vueInst.selectedSize.cuDebitare=false;
            vueInst.$q.loading.show();
            vueInst.getUm1Um2Forprodus();
        }
    }


    public getUm1Um2Forprodus(){
        const vueInst=this;
        vueInst.loadingStock = true;
        vueInst.loadingPrice = true;
        if(!vueInst.areArticlesWithoutDimensions) {
            ServiceBrowseArticles.identifyArticleInDB(vueInst.paramArticles.categoryPid.toString(), vueInst.selectedLength, vueInst.selectedWidth, vueInst.selectedThickness, vueInst.selectedDiameter, vueInst.selectedHeight, vueInst.selectedAlloy, vueInst.selectedEyes)
                .then(response => {
                    vueInst.$q.loading.hide();
                    console.log('getUm1Um2Forprodus=%o', response)
                    if (response.status == 'success') {
                        vueInst.qUm1 = 0;
                        vueInst.qUm2 = 0;
                        vueInst.inputFreeTextComments = '';
                        vueInst.selectedSize.um1=response.um1;
                        vueInst.selectedSize.um2=response.um2;
                        vueInst.categoryUM=response.um1;
                        vueInst.productCode=response.productCode;
                        vueInst.typeOfArticle=response.enumPlacaBara;
                        vueInst.densitate=response.densitate;
                        vueInst.isPlacaAluminiu=response.isPlacaAluminiu;
                        vueInst.selectedSize.um1_to_um2=response.um1ToUm2*1;
                        vueInst.selectedSize.cuDebitare=(response.cuDebitare=='y'? true : false);
                        ServiceProduct.getArticleStock(vueInst.userStore.user.id_team, vueInst.productCode).then(response => {
                            vueInst.loadingStock = false;
                            // console.log(response);
                            vueInst.stocArticol = response.itemStock;
                            vueInst.turnover_rate_month = response.turnover_rate_month;
                            vueInst.setStock();
                        });
                        // ServiceProduct.getArticlePrice(vueInst.productCode, vueInst.userStore.user.cif).then(response => {
                        //     vueInst.loadingPrice = false;
                        //     vueInst.labelPrice = response.price_huf + ' HUF / ' + vueInst.selectedSize.um1;
                        //     vueInst.price_huf = response.price_huf;
                        //     vueInst.price_eur = response.price_eur;
                        //     vueInst.labelPriceTotal = '0 HUF';
                        // });
                    }
                }).catch((error) => {
                vueInst.$q.loading.hide();
                vueInst.$q.notify({
                    color: 'red',
                    textColor: 'white',
                    type: 'negative',
                    icon: 'error',
                    position: 'top',
                    timeout: 3000,
                    message: error.toString()
                })
            });
        } else {
            console.log(vueInst.selectedProductCode);
            ServiceBrowseArticles.getArticleByProductCode(vueInst.selectedProductCode).then(response => {
                vueInst.$q.loading.hide();
                console.log('getUm1Um2Forprodus=%o', response);
                if (response.status == 'success') {
                    vueInst.qUm1 = 0;
                    vueInst.qUm2 = 0;
                    vueInst.inputFreeTextComments = '';
                    vueInst.selectedSize.um1=response.um1;
                    vueInst.selectedSize.um2=response.um2;
                    vueInst.productCode=response.productCode;
                    vueInst.typeOfArticle=response.enumPlacaBara;
                    vueInst.densitate=response.densitate;
                    vueInst.isPlacaAluminiu=response.isPlacaAluminiu;
                    vueInst.selectedSize.um1_to_um2=response.um1ToUm2*1;
                    vueInst.selectedSize.cuDebitare=(response.cuDebitare=='y'? true : false);
                    ServiceProduct.getArticleStock(vueInst.userStore.user.id_team, vueInst.selectedProductCode).then(response => {
                        vueInst.loadingStock = false;
                        // console.log(response);
                        vueInst.stocArticol = response.itemStock;
                        vueInst.turnover_rate_month = response.turnover_rate_month;
                        vueInst.setStock();
                    });
                    // console.log(response.um1);
                    // ServiceProduct.getArticlePrice(vueInst.productCode, vueInst.userStore.user.cif).then(response => {
                    //     vueInst.loadingPrice = false;
                    //     vueInst.labelPrice = response.price_huf + ' HUF / ' + vueInst.selectedSize.um1;
                    //     vueInst.price_huf = response.price_huf;
                    //     vueInst.price_eur = response.price_eur;
                    //     vueInst.labelPriceTotal = '0 HUF';
                    // });
                }
            }).catch((error) => {
                vueInst.$q.loading.hide();
                vueInst.$q.notify({
                    color: 'red',
                    textColor: 'white',
                    type: 'negative',
                    icon: 'error',
                    position: 'top',
                    timeout: 3000,
                    message: error.toString()
                })
            });
        }
    }

    public isLengthAvailable(sizeValue: number):boolean{
        const vueInst=this;
        let result=false;
        const sizeExist=vueInst.possibleSizes.find(size=>{
            return size.l==sizeValue;
        })
        if(sizeExist){result=true;}
        return result;
    }

    public isWidthAvailable(sizeValue: number):boolean{
        const vueInst=this;
        let result=false;
        const sizeExist=vueInst.possibleSizes.find(size=>{
            return size.w==sizeValue;
        })
        if(sizeExist){result=true;}
        return result;
    }

    public isThicknessAvailable(sizeValue: number):boolean{
        const vueInst=this;
        let result=false;
        const sizeExist=vueInst.possibleSizes.find(size=>{
            return size.t==sizeValue;
        })
        if(sizeExist){result=true;}
        return result;
    }

    public isDiameterAvailable(sizeValue: number):boolean{
        const vueInst=this;
        let result=false;
        const sizeExist=vueInst.possibleSizes.find(size=>{
            return size.d==sizeValue;
        })
        if(sizeExist){result=true;}
        return result;
    }

    public isHeightAvailable(sizeValue: number):boolean{
        const vueInst=this;
        let result=false;
        const sizeExist=vueInst.possibleSizes.find(size=>{
            return size.h==sizeValue;
        })
        if(sizeExist){result=true;}
        return result;
    }

    public isAlloyAvailable(sizeValue: string):boolean{
        const vueInst=this;
        let result=false;
        const sizeExist=vueInst.possibleSizes.find(size=>{
            return size.a==sizeValue;
        })
        if(sizeExist){result=true;}
        return result;
    }

    public isTypeAvailable(sizeValue: number):boolean{
        const vueInst=this;
        let result=false;
        const sizeExist=vueInst.possibleSizes.find(size=>{
            return size.k==sizeValue;
        })
        if(sizeExist){result=true;}
        return result;
    }

    public isEyes2Available(sizeValue: number):boolean{
        const vueInst=this;
        let result=false;
        const sizeExist=vueInst.possibleSizes.find(size=>{
            return size.e==sizeValue;
        })
        if(sizeExist){result=true;}
        return result;
    }

    public isMeter(){
        const vueInst=this;
        // console.log(vueInst.categoryUM);
        return vueInst.categoryUM == 'M' ? true : false;
    }

    // public isRollWeightAvailable(sizeValue: number):boolean{
    //     const vueInst=this;
    //     let result=false;
    //     const sizeExist=vueInst.possibleSizes.find(size=>{
    //         return size.g==sizeValue;
    //     })
    //     if(sizeExist){result=true;}
    //     return result;
    // }

    public get HideUm1IfBucDebit():boolean{
        if(this.dorescDebitare && this.selectedSize.um1 && this.selectedSize.um1==='BUC'){
            return true;
        }
        else{return false;}
    }

    // public get HideUm2IfBucDebit():boolean{
    //     if(this.dorescDebitare && this.selectedSize.um2 && this.selectedSize.um2==='BUC'){
    //         return true;
    //     }
    //     else{return false;}
    // }

    public changeTipUm(val: boolean, tip: number){
       
        if(!this.selectedUM1 && !this.selectedUM2) {
            if(tip == 1) {
                this.selectedUM2 = true;
            } else {
                this.selectedUM1 = true;
            }
        }
        if(this.selectedSize.um1 == '' || this.selectedSize.um1 == null) {
            this.selectedUM2 = true;
        }
        // if(this.selectedSize.um2 == '' || this.selectedSize.um2 == null) {
        //     this.selectedUM1 = true;
        // }
        if(this.selectedUM1 && this.selectedUM2) {
            this.tip_um = 'um12';
        } else if(this.selectedUM1) {
            this.tip_um = 'um1';
        } else if(this.selectedUM2) {
            this.tip_um = 'um2';
        }
    }

    public addProductToBasket(){
        const vueInst=this;
        if (!vueInst.articleCouldBePutInBasket) {
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                icon: 'error',
                position: 'top',
                timeout: 1500,
                html: true,
                message: this.$t('message.check_all_features')
            })
            return false;
        } else if (vueInst.pidCategory != vueInst.paramArticles.categoryPid.toString()) {
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                icon: 'error',
                position: 'top',
                timeout: 1500,
                message: this.$t('message.errors_app')
            })
        } else if(vueInst.ErrorRuleIntegerNumberBuc && vueInst.selectedLength) {
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                icon: 'error',
                position: 'top',
                timeout: 1500,
                message: this.$t('message.error6m').replace('xx', vueInst.selectedLength.toString())
            })
        } else {
            vueInst.$q.loading.show();
            // if(vueInst.dorescDebitare) {
            //     vueInst.inputFreeTextComments = vueInst.inputCuttingDetails;
            // }
            if(!vueInst.areArticlesWithoutDimensions){
                ServiceBasket.identifyArticleInDBAndPutIntoBasket(vueInst.paramArticles.categoryPid.toString()
                    , vueInst.selectedLength
                    , vueInst.selectedWidth
                    , vueInst.selectedThickness
                    , vueInst.selectedDiameter
                    , vueInst.selectedHeight
                    , vueInst.selectedAlloy
                    , vueInst.selectedEyes
                    , vueInst.selectedSize.um1
                    , vueInst.selectedSize.um2
                    , vueInst.qUm1
                    , vueInst.qUm2
                    , vueInst.nrBucati
                    , vueInst.dorescDebitare
                    , vueInst.cuttingLength
                    , vueInst.cuttingWidth
                    , latinize(vueInst.inputFreeTextComments)
                    , vueInst.tip_um)
                    .then(response => {
                        if (response.status == 'success') {
                            getBasket();
                            vueInst.onPidCategoryChanged(vueInst.pidCategory, vueInst.pidCategory);
                            vueInst.$q.loading.hide();
                            vueInst.$q.notify({
                                color: 'orange-9',
                                textColor: 'white',
                                icon: 'shopping_cart',
                                position: 'top',
                                timeout: 1000,
                                message: this.$t('message.product_added_to_basket')
                            })
                        }
                    }).catch((error) => {
                    vueInst.$q.loading.hide();
                    vueInst.$q.notify({
                        color: 'red',
                        textColor: 'white',
                        type: 'negative',
                        icon: 'error',
                        position: 'top',
                        timeout: 3000,
                        message: error.toString()
                    })
                });
            } else {
                ServiceBasket.identifyArticleInDBAndPutIntoBasketNoDimensions(vueInst.selectedProductCode
                    , vueInst.selectedSize.um1
                    , vueInst.selectedSize.um2
                    , vueInst.qUm1
                    , vueInst.qUm2
                    , vueInst.nrBucati
                    , vueInst.dorescDebitare
                    , latinize(vueInst.inputFreeTextComments))
                    .then(response => {
                        if (response.status == 'success') {
                            getBasket();
                            vueInst.onPidCategoryChanged(vueInst.pidCategory, vueInst.pidCategory);
                            vueInst.$q.loading.hide();
                            vueInst.$q.notify({
                                color: 'orange-9',
                                textColor: 'white',
                                icon: 'shopping_cart',
                                position: 'top',
                                timeout: 1000,
                                message: this.$t('message.product_added_to_basket')
                            })
                        }
                    }).catch((error) => {
                    vueInst.$q.loading.hide();
                    vueInst.$q.notify({
                        color: 'red',
                        textColor: 'white',
                        type: 'negative',
                        icon: 'error',
                        position: 'top',
                        timeout: 3000,
                        message: error.toString()
                    })
                });
            }

        }
    }


    public addProductToCerere(){
        const vueInst=this;
            if (!vueInst.articleCouldBePutInBasket) {
                vueInst.$q.notify({
                    color: 'red',
                    textColor: 'white',
                    icon: 'error',
                    position: 'top',
                    timeout: 1500,
                    html: true,
                    message: this.$t('message.check_all_features')
                })
                return false;
            } else if (vueInst.pidCategory != vueInst.paramArticles.categoryPid.toString()) {
                vueInst.$q.notify({
                    color: 'red',
                    textColor: 'white',
                    icon: 'error',
                    position: 'top',
                    timeout: 1500,
                    message: this.$t('message.errors_app')
                })
            } else if(vueInst.ErrorRuleIntegerNumberBuc) {
                vueInst.$q.notify({
                    color: 'red',
                    textColor: 'white',
                    icon: 'error',
                    position: 'top',
                    timeout: 1500,
                    message: this.$t('message.error6m')
                })
            }else {
                vueInst.$q.loading.show();
                if(!vueInst.areArticlesWithoutDimensions){
                    ServiceBrowseArticles.identifyArticleInDB(vueInst.paramArticles.categoryPid.toString(), vueInst.selectedLength, vueInst.selectedWidth, vueInst.selectedThickness, vueInst.selectedDiameter, vueInst.selectedHeight, vueInst.selectedAlloy, vueInst.selectedEyes)
                        .then(response => {
                            if (response.status == 'success') {
                                vueInst.$q.loading.hide();
                                const artPentruCerere:TProductBasketMarkedFavorites={
                                    appid:'0',
                                    productPid:response.productPid,
                                    categoryPid: response.categoryPid,
                                    productCode:response.productCode,
                                    productNameRo: response.productNameRo,
                                    productNameEn: response.productNameEn,
                                    productNameHu: response.productNameHu,
                                    categoryName: '',
                                    qBuc: vueInst.nrBucati,
                                    q_um_base: vueInst.qUm1,
                                    qUm1 :vueInst.qUm1,
                                    qUm2: vueInst.qUm2,
                                    um1: response.um1,
                                    um2: response.um2,
                                    um1_to_um2 : response.um1ToUm2,
                                    l: vueInst.selectedLength,
                                    w:vueInst.selectedWidth,
                                    t:vueInst.selectedThickness,
                                    d:vueInst.selectedDiameter,
                                    h:vueInst.selectedHeight,
                                    a:vueInst.selectedAlloy,
                                    k:vueInst.selectedEyes,
                                    e:vueInst.selectedEyes2,
                                    dorescDebitare:vueInst.dorescDebitare,
                                    cuttingLength:vueInst.cuttingLength,
                                    cuttingWidth:vueInst.cuttingWidth,
                                    enumPlacaBara:response.enumPlacaBara,
                                    densitate:response.densitate,
                                    isInFavorite:vueInst.storeFavorites.favorites.some(favorite=>{return favorite.productCode==response.productCode}),
                                    observatii:latinize(vueInst.inputFreeTextComments),
                                    nr_ord:0,
                                    tip_um: vueInst.tip_um,
                                    price_eur: vueInst.price_eur,
                                    price_huf: vueInst.price_huf
                                }
                                vueInst.EventBusStore.set_event({name:'eventPutArticleInCerere',params:{ artPentruCerere: JSON.stringify(artPentruCerere)}});
                            }
                        }).catch((error) => {
                        vueInst.$q.loading.hide();
                        vueInst.$q.notify({
                            color: 'red',
                            textColor: 'white',
                            type: 'negative',
                            icon: 'error',
                            position: 'top',
                            timeout: 3000,
                            message: error.toString()
                        })
                    });
                } else {
                    ServiceBrowseArticles.getArticleByProductCode(vueInst.selectedProductCode)
                        .then(response => {
                            vueInst.$q.loading.hide();
                            if (response.status == 'success') {
                                const artPentruCerere:TProductBasketMarkedFavorites={
                                    appid:'0',
                                    productPid:response.productPid,
                                    categoryPid: response.categoryPid,
                                    productCode:response.productCode,
                                    productNameRo: response.productNameRo,
                                    productNameEn: response.productNameEn,
                                    productNameHu: response.productNameHu,
                                    categoryName: '',
                                    qBuc: vueInst.nrBucati,
                                    q_um_base: vueInst.qUm1,
                                    qUm1 :vueInst.qUm1,
                                    qUm2: vueInst.qUm2,
                                    um1: response.um1,
                                    um2: response.um2,
                                    um1_to_um2 : response.um1ToUm2,
                                    l: vueInst.selectedLength,
                                    w:vueInst.selectedWidth,
                                    t:vueInst.selectedThickness,
                                    d:vueInst.selectedDiameter,
                                    h:vueInst.selectedHeight,
                                    a:vueInst.selectedAlloy,
                                    k:vueInst.selectedEyes,
                                    e:vueInst.selectedEyes2,
                                    dorescDebitare:vueInst.dorescDebitare,
                                    cuttingLength:vueInst.cuttingLength,
                                    cuttingWidth:vueInst.cuttingWidth,
                                    enumPlacaBara:response.enumPlacaBara,
                                    densitate:response.densitate,
                                    isInFavorite:vueInst.storeFavorites.favorites.some(favorite=>{return favorite.productCode==response.productCode}),
                                    observatii:latinize(vueInst.inputFreeTextComments),
                                    nr_ord:0,
                                    tip_um: vueInst.tip_um,
                                    price_eur: vueInst.price_eur,
                                    price_huf: vueInst.price_huf
                                }
                                vueInst.EventBusStore.set_event({name:'eventPutArticleInCerere',params:{ artPentruCerere: JSON.stringify(artPentruCerere)}});
                            }
                        }).catch((error) => {
                        vueInst.$q.loading.hide();
                        vueInst.$q.notify({
                            color: 'red',
                            textColor: 'white',
                            type: 'negative',
                            icon: 'error',
                            position: 'top',
                            timeout: 3000,
                            message: error.toString()
                        })
                    });
                }

            }
        // }
    }

    get existaArticole():boolean{
        if(this.paramArticles.arrSizes && this.paramArticles.arrSizes.length>0){
            return true;
        }
        else{
            return false;
        }
    }


    get articleCouldBePutInBasket():boolean{
        let lengthIsOk=this.paramArticles.withLength=='n'||false;
        let widthIsOk=this.paramArticles.withWidth=='n'||false;
        let thicknessIsOk=this.paramArticles.withThickness=='n'||false;
        let diameterIsOk=this.paramArticles.withDiameter=='n'||false;
        let heightIsOk=this.paramArticles.withHeight=='n'||false;
        let alloyIsOk=this.paramArticles.withAlloy=='n'||false;
        let eyesIsOk=this.paramArticles.withEyes=='n'||false;
        let eyesIsOk2=this.paramArticles.withEyes2=='n'||false;
        if(this.paramArticles.withLength=='y' && this.selectedLength){lengthIsOk=true;}
        if(this.paramArticles.withWidth=='y' && this.selectedWidth){widthIsOk=true;}
        if(this.paramArticles.withThickness=='y' && this.selectedThickness){thicknessIsOk=true;}
        if(this.paramArticles.withDiameter=='y' && this.selectedDiameter){diameterIsOk=true;}
        if(this.paramArticles.withHeight=='y' && this.selectedHeight){heightIsOk=true;}
        if(this.paramArticles.withAlloy=='y' && this.selectedAlloy){alloyIsOk=true;}
        if(this.paramArticles.withEyes=='y' && this.selectedEyes){eyesIsOk=true;}
        if(this.paramArticles.withEyes2=='y' && this.selectedEyes2){eyesIsOk2=true;}
        return lengthIsOk && widthIsOk && thicknessIsOk && diameterIsOk && heightIsOk && alloyIsOk && eyesIsOk && eyesIsOk2 && this.qUm1>0;
    }

    get clientWasSomethingElse():boolean{
        let lengthIsOk=this.paramArticles.withLength=='n'||false;
        let widthIsOk=this.paramArticles.withWidth=='n'||false;
        let thicknessIsOk=this.paramArticles.withThickness=='n'||false;
        let diameterIsOk=this.paramArticles.withDiameter=='n'||false;
        let heightIsOk=this.paramArticles.withHeight=='n'||false;
        let alloyIsOk=this.paramArticles.withAlloy=='n'||false;
        let eyesIsOk=this.paramArticles.withEyes=='n'||false;
        if(this.paramArticles.withLength=='y' && this.selectedLength){lengthIsOk=true;}
        if(this.paramArticles.withWidth=='y' && this.selectedWidth){widthIsOk=true;}
        if(this.paramArticles.withThickness=='y' && this.selectedThickness){thicknessIsOk=true;}
        if(this.paramArticles.withDiameter=='y' && this.selectedDiameter){diameterIsOk=true;}
        if(this.paramArticles.withHeight=='y' && this.selectedHeight){heightIsOk=true;}
        if(this.paramArticles.withAlloy=='y' && this.selectedAlloy){alloyIsOk=true;}
        if(this.paramArticles.withEyes=='y' && this.selectedEyes){eyesIsOk=true;}
        return lengthIsOk && widthIsOk && thicknessIsOk && diameterIsOk && heightIsOk && alloyIsOk && eyesIsOk && this.nrBucati>0 || this.inputFreeTextComments.length>3;
    }

    get articleCouldBePutInFavorites():boolean{
        let lengthIsOk=this.paramArticles.withLength=='n'||false;
        let widthIsOk=this.paramArticles.withWidth=='n'||false;
        let thicknessIsOk=this.paramArticles.withThickness=='n'||false;
        let diameterIsOk=this.paramArticles.withDiameter=='n'||false;
        let heightIsOk=this.paramArticles.withHeight=='n'||false;
        let alloyIsOk=this.paramArticles.withAlloy=='n'||false;
        let eyesIsOk=this.paramArticles.withEyes=='n'||false;
        let eyesIsOk2=this.paramArticles.withEyes2=='n'||false;
        if(this.paramArticles.withLength=='y' && this.selectedLength){lengthIsOk=true;}
        if(this.paramArticles.withWidth=='y' && this.selectedWidth){widthIsOk=true;}
        if(this.paramArticles.withThickness=='y' && this.selectedThickness){thicknessIsOk=true;}
        if(this.paramArticles.withDiameter=='y' && this.selectedDiameter){diameterIsOk=true;}
        if(this.paramArticles.withHeight=='y' && this.selectedHeight){heightIsOk=true;}
        if(this.paramArticles.withAlloy=='y' && this.selectedAlloy){alloyIsOk=true;}
        if(this.paramArticles.withEyes=='y' && this.selectedEyes){eyesIsOk=true;}
        if(this.paramArticles.withEyes2=='y' && this.selectedEyes2){eyesIsOk2=true;}
        return lengthIsOk && widthIsOk && thicknessIsOk && diameterIsOk && heightIsOk && alloyIsOk && eyesIsOk && eyesIsOk2 && (this.productCode != null);
    }

    get articleIsOnFavorites():boolean{
        const vueInst=this;
        const articleFavorite=vueInst.storeFavorites.favorites.find(favorite=> {
            let lengthIsTheSame = false;
            let widthIsTheSame = false;
            let thicknessIsTheSame = false;
            let diameterIsTheSame = false;
            let heightIsTheSame = false;
            let alloyIsTheSame = false;
            let eyesIsTheSame = false;
            let eyesIsTheSame2 = false;
            const categoryIsTheSame= (favorite.categoryPid.toString()==vueInst.paramArticles.categoryPid.toString());
            if (vueInst.paramArticles.withLength == 'y') {
                    if (vueInst.selectedLength && vueInst.selectedLength == favorite.l) {lengthIsTheSame = true;} else {lengthIsTheSame = false;}
                } else {lengthIsTheSame = true;}
            if (vueInst.paramArticles.withWidth == 'y') {
                if (vueInst.selectedWidth && vueInst.selectedWidth == favorite.w) {widthIsTheSame = true;} else {widthIsTheSame = false;}
            } else {widthIsTheSame = true;}
            if (vueInst.paramArticles.withThickness == 'y') {
                if (vueInst.selectedThickness && vueInst.selectedThickness == favorite.t) {thicknessIsTheSame = true;} else {thicknessIsTheSame = false;}
            } else {thicknessIsTheSame = true;}
            if (vueInst.paramArticles.withDiameter == 'y') {
                if (vueInst.selectedDiameter && vueInst.selectedDiameter == favorite.d) {diameterIsTheSame = true;} else {diameterIsTheSame = false;}
            } else {diameterIsTheSame = true;}
            if (vueInst.paramArticles.withHeight == 'y') {
                if (vueInst.selectedHeight && vueInst.selectedHeight == favorite.h) {heightIsTheSame = true;} else {heightIsTheSame = false;}
            } else {heightIsTheSame = true;}
            if (vueInst.paramArticles.withAlloy == 'y') {
                if (vueInst.selectedAlloy && vueInst.selectedAlloy == favorite.a) {alloyIsTheSame = true;} else {alloyIsTheSame = false;}
            } else {alloyIsTheSame = true;}
            if (vueInst.paramArticles.withEyes == 'y') {
                if (vueInst.selectedEyes && vueInst.selectedEyes == favorite.k) {eyesIsTheSame = true;} else {eyesIsTheSame = false;}
            } else {eyesIsTheSame = true;}
            if (vueInst.paramArticles.withEyes2 == 'y') {
                if (vueInst.selectedEyes2 && vueInst.selectedEyes2 == favorite.e) {eyesIsTheSame2 = true;} else {eyesIsTheSame2 = false;}
            } else {eyesIsTheSame2 = true;}
            return categoryIsTheSame && lengthIsTheSame && widthIsTheSame && thicknessIsTheSame && diameterIsTheSame && heightIsTheSame && alloyIsTheSame && eyesIsTheSame && eyesIsTheSame2;
        });
        if(articleFavorite){return true;}else{return false;}
    }

    public getDetailsArticleFromDB(){
        const vueInst=this;
        // vueInst.pid = vueInst.productPid;
        ServiceProduct.getArticleFromDB(vueInst.productPid).then(response=>{
            vueInst.$q.loading.hide();
            if(response.status=='success'){
                console.log('getDetailsArticleFromDB=%o',response)
                if(response.product.withLength=='y' && response.product.l){vueInst.setSize('l',response.product.l)}
                if(response.product.withWidth=='y' && response.product.w){vueInst.setSize('w',response.product.w)}
                if(response.product.withThickness=='y' && response.product.t){vueInst.setSize('t',response.product.t)}
                if(response.product.withDiameter=='y' && response.product.d){vueInst.setSize('d',response.product.d)}
                if(response.product.withHeight=='y' && response.product.h){vueInst.setSize('h',response.product.h)}
                if(response.product.withAlloy=='y' && response.product.a){vueInst.setSize('a',response.product.a)}
                if(response.product.withEyes=='y' && response.product.k){vueInst.setSize('k',response.product.k)}
                if(response.product.withEyes2=='y' && response.product.e){vueInst.setSize('e',response.product.e)}
                // vueInst.getUm1Um2Forprodus();
            }
        })
    }

    public coreleazaUm1Um2(umModificat:string){
        const vueInst=this;
       if(umModificat=='um1'){
            if(vueInst.selectedSize.um2 == 0) {
                vueInst.qUm2 = vueInst.qUm1;
            } else {
                vueInst.qUm2=Number((vueInst.qUm1*1*vueInst.selectedSize.um2).toFixed(3));
            }
            if(vueInst.isMeter() && vueInst.selectedLength && vueInst.qUm1 % vueInst.selectedLength == 0) {
                vueInst.qUm2=Number(((vueInst.qUm1/vueInst.selectedLength)*1*vueInst.selectedSize.um1_to_um2).toFixed(3));
            }
       }
       vueInst.setStock();
    }

    public setStock() {
        const vueInst = this;
        if(vueInst.stocArticol == 0) { //red
            vueInst.labelStock = this.$t('message.on_demand_stock');
            vueInst.labelStockColor = '#FF0000';
        } else if(vueInst.qUm2 > vueInst.stocArticol) {//orange
            vueInst.labelStock = this.$t('message.partial_stock');
            vueInst.labelStockColor = '#FFA500';
        } else if(vueInst.turnover_rate_month > 30){//green
            vueInst.labelStock = this.$t('message.in_stock');
            vueInst.labelStockColor = '#008000';
        } else {//yellow
            vueInst.labelStock = this.$t('message.low_stock');
            vueInst.labelStockColor = '#CCCC14';
        }
    }

    public punePeZeroDimensiuni(){
        this.qUm1=0;
        this.qUm2=0;
        if(this.dorescDebitare) {
            this.selectedUM1 = true;
            this.selectedUM2 = true;
            this.tip_um = 'um12';
        }
    }

    public get ErrorRuleLengthDebitarePlaca():boolean {
        
        // console.log('error rule: ' + this.cuttingLength + ' ' + this.typeOfArticle);
        if(this.cuttingLength) {
            if (this.typeOfArticle == 'placa') {
                if(Number(this.cuttingLength) < this.minLengthPlaci) {
                    this.msjErrorRuleCuttingLength = this.$t('message.length_smaller_than') + this.minLengthPlaci + 'mm';
                    return true;
                } else if(Number(this.cuttingLength) > this.maxLengthPlaci) {
                    this.msjErrorRuleCuttingLength = this.$t('message.length_bigger_than') + this.maxLengthPlaci + 'mm';
                    return true;
                } 
            } else {
                if(Number(this.cuttingLength) < this.minLengthBare) {
                    this.msjErrorRuleCuttingLength = this.$t('message.length_smaller_than') + this.minLengthBare + 'mm';
                    return true;
                } else if(Number(this.cuttingLength) > this.maxLengthBare) {
                    this.msjErrorRuleCuttingLength = this.$t('message.length_bigger_than') + this.maxLengthBare + 'mm';
                    return true;
                }
            }
        }
        return false;
    }

    public get ErrorRuleWidthDebitarePlaca():boolean{
        if(this.cuttingWidth){
            if (Number(this.cuttingWidth)<this.minLengthPlaci) {
                this.msjErrorRuleCuttingWidth= this.$t('message.width_smaller_than') + this.minLengthPlaci + 'mm';
                return true;
            } else if(Number(this.cuttingWidth) > this.maxLengthPlaci){
                this.msjErrorRuleCuttingWidth= this.$t('message.width_bigger_than') + this.maxLengthPlaci + 'mm';
                return true;
            }
        }
        return false;
    }

    public get ErrorRuleIntegerNumberBuc(): boolean {
        if( this.selectedSize.um1 == 'M' && this.selectedLength) {
            console.log(this.qUm1 / this.selectedLength);
            const res = this.qUm1 / this.selectedLength;
            return (res % 1 !== 0);
        }

        return false;
    }

    public coreleazaUm1Um2CuDebitare(){
        const vueInst=this;
        let qML=0;
        let qBuc=0;
        let qKg=0;
        if(vueInst.typeOfArticle==='bara') {
            // eslint-disable-next-line no-debugger
            //debugger;
            qBuc=vueInst.nrBucati;
            qML= (vueInst.cuttingLength*vueInst.nrBucati)/1000;
            qKg=Number((qML*1*vueInst.selectedSize.um1_to_um2).toFixed(2));
            if(vueInst.selectedSize.um1==='KG'){
                vueInst.qUm1=qKg;
            } else if(vueInst.selectedSize.um1==='M'){
                vueInst.qUm1=qML;
            }

            // if(vueInst.selectedSize.um2==='ML'){
            //     vueInst.qUm2=qML;
            // } else if(vueInst.selectedSize.um2==='KG'){
            //     vueInst.qUm2=qKg;
            // }
        }
        if(vueInst.typeOfArticle==='placa'){
            let kg_per_buc=0;
            if(vueInst.densitate && vueInst.selectedThickness && vueInst.typeOfArticle==='placa'){
                kg_per_buc = Math.round(((vueInst.cuttingLength*vueInst.cuttingWidth*vueInst.selectedThickness/1000000)*vueInst.densitate + Number.EPSILON) * 100) / 100

                // console.log('coreleaza um1 um2 cu debitare ' + kg_per_buc + ' ' + qML + ' ' + qBuc);
                if(vueInst.selectedSize.um1==='M' || vueInst.selectedSize.um1==='BUC') {
                    vueInst.qUm1 = vueInst.nrBucati;
                    vueInst.qUm2=Number((kg_per_buc*vueInst.qUm1).toFixed(2));
                }
                // if(vueInst.selectedSize.um2==='M') {
                //     vueInst.qUm2 = vueInst.nrBucati;
                //     vueInst.qUm1=Number((kg_per_buc*vueInst.qUm2).toFixed(2));
                // }
            }
        }

       // $this->kg_total=$this->kg_per_buc*$this->buc;

    }

    public calculDimensiuniDebitare(){
        console.log('calculDimensiuniDebitare')
        const vueInst=this;
        if(vueInst.productCode) {
            ServiceBrowseArticles.calculDimensiuniDebitare(vueInst.productCode, vueInst.cuttingLength, vueInst.nrBucati).then(response => {
                vueInst.$q.loading.hide();
                if (response.status == 'success') {
                    console.log('getDetailsArticleFromDB=%o', response)
                    if (response.q_um1) {
                        vueInst.qUm1=response.q_um1;
                    }
                    if (response.q_um2) {
                        vueInst.qUm2=response.q_um2;
                    }
                }
            })
        }
    }

    public get minLengthPlaci():number {
        return this.storeNomenclatoare.minLengthPlaci;
    }

    public get minLengthBare():number {
        if(this.pid == '36' || this.pid == '56' || this.pid == '8' || this.pid == '7'){
            return 10;
        }
        return this.storeNomenclatoare.minLengthBare;
    }

    public get maxLengthPlaci():number {
        return this.storeNomenclatoare.maxLengthPlaci;
    }

    public get maxLengthBare():number {
        return this.storeNomenclatoare.maxLengthBare;
    }

    public get labelBtnAdaugaBasket():string{
        let result= this.$t('message.add_in_basket');
        if(this.typeOfAccess === 'fromDashboard' ){
            result = this.$t('message.add_in_basket');
        }
        if(this.typeOfAccess === 'fromCerere' || this.typeOfAccess === 'fromOferta' ){
            result = this.$t('message.add_to_request');
        }
        return result;
    }

    public resetVariables(){
        console.log('reset variables');
        const vueInst=this;
        vueInst.nrBucati=0;
        vueInst.cuttingLength=0;
        vueInst.cuttingWidth=0;
        vueInst.dorescDebitare=false;
        vueInst.qUm1=0;
        vueInst.qUm2=0;
        vueInst.selectedLength = null;
        vueInst.selectedWidth = null;
        vueInst.selectedThickness = null;
        vueInst.selectedDiameter = null;
        vueInst.selectedHeight = null;
        vueInst.selectedAlloy = null;
        vueInst.selectedEyes = null;
        vueInst.selectedEyes2 = null;
        vueInst.isPlacaAluminiu = '0';
        vueInst.typeOfArticle=TEnumPlacaBara.others;
        vueInst.inputFreeTextComments = '';
        vueInst.inputCuttingDetails='';
        vueInst.initializeSelectedSize();
        vueInst.selectedUM1 = true;
        vueInst.selectedUM2 = true;
        vueInst.tip_um = 'um12';
        vueInst.loadingStock = false;
        vueInst.labelStock = '';
        vueInst.loadingPrice = false;
        vueInst.labelPrice = '';
        vueInst.labelPriceTotal = '';
        vueInst.price_eur = 0;
        vueInst.price_huf = 0;
        vueInst.stocArticol = 0;
        vueInst.turnover_rate_month = 0;
        vueInst.selectedProductCode = '';
        vueInst.appid = 0;

        this.paramArticles= {
            status: '',
            message: '',
            arrLength: [],
            arrWidth: [],
            arrThickness: [],
            arrDiameter: [],
            arrHeight: [],
            arrAlloy: [],
            arrType: [],
            arrEyes2: [],
            categoryNameRo: '',
            categoryNameEn: '',
            categoryNameHu: '',
            categoryPid: 0,
            isParentForArticles: '',
            withDiameter: 'n',
            withLength: 'n',
            withThickness: 'n',
            withWidth: 'n',
            withHeight: 'n',
            withAlloy: 'n',
            withEyes: 'n',
            withEyes2: 'n',
            positionLength: 0,
            positionWidth: 0,
            positionThickness: 0,
            positionDiameter: 0,
            positionHeight: 0,
            positionAlloy: 0,
            positionEyes:0,
            positionEyes2:0,
            arrSizes: [],
            hierarchicalChain:[]
        }
    }


    // @Watch('pidCategory', { immediate: true, deep: false })
    onPidCategoryChanged(newVal: string, oldVal: string) {
        console.log('Watch onPidCategoryChanged')
        const vueInst=this;
        vueInst.resetVariables();
        vueInst.$q.loading.show();
        vueInst.pid = newVal;
        ServiceBrowseArticles.getDetailsArticlesFromDB(newVal).then(response=>{
            if(response.status=='success'){
                vueInst.qUm1=0;
                vueInst.qUm2=0;
                // vueInst.initializeSelectedSize();
                vueInst.paramArticles=JSON.parse(JSON.stringify(response));
                vueInst.possibleSizes=JSON.parse(JSON.stringify(response.arrSizes));
                vueInst.areArticlesWithoutDimensions = response.withLength == 'n' && response.withWidth == 'n' && response.withThickness == 'n' && 
                                                        response.withDiameter == 'n' && response.withHeight == 'n';
                vueInst.categoryUM  = response.categoryUM;
                console.log(vueInst.areArticlesWithoutDimensions);
                if(vueInst.areArticlesWithoutDimensions) {
                    vueInst.getArticlesForCategory(newVal);
                    if(vueInst.code && vueInst.code!='0') {
                        vueInst.selectedProductCode = vueInst.code;
                        vueInst.getUm1Um2Forprodus();
                    }
                } else {
                    if(vueInst.productPid && vueInst.productPid!='0') {
                        vueInst.$q.loading.show();
                        vueInst.getDetailsArticleFromDB();
                    }
                }
                
                vueInst.$q.loading.hide();
            }
        })
    }

    public getArticlesForCategory(pid_category: string){
        const vueInst = this;
        ServiceBrowseArticles.getArticlesForCategory(pid_category).then(response => {
            if(response.status=='success'){
                vueInst.products = response.products;
                // if(vueInst.code && vueInst.code!='0'){
                //     vueInst.selectProduct(vueInst.code);
                // }
            }
        });
    }

    public openContact(){
        this.$router.push({name: 'ContactFormular'});
    }

    public activated(){
        const vueInst=this;
        console.log('activated');
        vueInst.userStore.set_page_transition('fade-in-right');
        if(vueInst.$q.platform.is.mobile) {
            vueInst.userStore.set_showbackbar(true);
            vueInst.userStore.set_title_back_bar(this.$t('message.item_details'));
        }
        if(vueInst.$q.platform.is.mobile){
            vueInst.dialogTransitionShow ='slide-left';
            vueInst.dialogTransitionHide ='slide-right';
        }else{
            vueInst.dialogTransitionShow ='slide-right';
            vueInst.dialogTransitionHide ='slide-left';
        }
        vueInst.appid = 0;
        ServiceLogs.updateTimeSpentPerPage('browse_articles', vueInst.pidCategory, vueInst.appid).then(responce => {
            if(responce.status == 'success'){
                vueInst.appid = responce.appid;
            }
        })
        vueInst.onPidCategoryChanged(vueInst.pidCategory, vueInst.pidCategory);
        window.addEventListener('beforeunload', function (event) {
            
            ServiceLogs.updateTimeSpentPerPage('browse_articles', vueInst.pidCategory, vueInst.appid);
        });
    }

    public mounted(): void {
        
        const vueInst=this;
        if(vueInst.typeOfAccess === 'fromCerere'){
            vueInst.onPidCategoryChanged(vueInst.pidCategory, vueInst.pidCategory);
        }

        window.addEventListener('beforeunload', function (event) {
            
            ServiceLogs.updateTimeSpentPerPage('browse_articles', vueInst.pidCategory, vueInst.appid);
        });
    }

    deactivated(): void {
        const vueInst=this;
        ServiceLogs.updateTimeSpentPerPage('browse_articles', vueInst.pidCategory, vueInst.appid);
    }
}
