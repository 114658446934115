import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import {TArticle} from "@/types/TArticle";
import {TProductBasket} from '@/types/TProductBasket';

type TProduct=TProductBasket & {withLength: string;withWidth: string;withThickness: string; withDiameter: string; withHeight: string; withAlloy: string; withEyes: string; withEyes2: string;isPlacaAluminiu:string}

interface TAjaxResponseSimple {
    status: string;
    message: string;
}

interface TAjaxResponseGetArticleStock {
    status: string;
    message: string;
    itemStock:number;
    turnover_rate_month: number;
}

interface TAjaxResponseGetArticlePrice {
    status: string;
    message: string;
    price_huf:number;
    price_eur:number;
}



interface TAjaxResponseGetArticle {
    status: string;
    message: string;
    product:TProduct;
}

export class ServiceProduct {

    public static async getArticleFromDB(pid:string): Promise<TAjaxResponseGetArticle> {
        const rnd=Math.random();
        const response = await axios.get(`${CONFIG_ENV.URL_ARTICOL.articol}/${pid}/${rnd}`);
        return response.data;
    }

    public static async getArticleStock(id_team: string, productCode:string): Promise<TAjaxResponseGetArticleStock> {
        const team = id_team == '1' ? 'CS' : 'SE';
        const response = await axios.get(`${CONFIG_ENV.URL_ARTICOL.get_article_stock}/${team}/${productCode}`);
        return response.data;
    }

    public static async getArticlePrice(productCode:string, cif: string): Promise<TAjaxResponseGetArticlePrice> {
        const response = await axios.get(`${CONFIG_ENV.URL_ARTICOL.get_article_price}/${productCode}/${cif}`);
        return response.data;
    }
}
