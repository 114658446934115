import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46ce1106"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "q-pa-lg ecran-container" }
const _hoisted_2 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_3 = {
  key: 0,
  class: "app__page__title__container"
}
const _hoisted_4 = { style: {"white-space":"nowrap","font-size":"2rem"} }
const _hoisted_5 = {
  key: 0,
  style: {"size":"1.5rem","font-weight":"bold"}
}
const _hoisted_6 = { class: "grid-container" }
const _hoisted_7 = { class: "grid-item" }
const _hoisted_8 = { class: "grid-item" }
const _hoisted_9 = { class: "grid-item" }
const _hoisted_10 = { class: "grid-item" }
const _hoisted_11 = {
  key: 2,
  class: "q-pa-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$q.platform.is.desktop)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_q_icon, {
                name: "paid",
                style: {"font-size":"2rem","position":"relative","top":"-3px"},
                color: "primary"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('message.balance')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.$q.platform.is.desktop)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 1,
            flat: "",
            color: "blue",
            label: _ctx.$t('message.download_balance_pdf'),
            "no-caps": "",
            icon: "picture_as_pdf",
            loading: _ctx.downloadingFile,
            style: {"margin-left":"auto"},
            onClick: _ctx.downloadBalantaPdf
          }, null, 8, ["label", "loading", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.existaBalanta)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('message.interval')) + " 01.01.2022 - " + _toDisplayString(_ctx.currentDateAsString), 1),
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('message.initial_amount')) + " " + _toDisplayString(_ctx.balantaInNav.SoldInit) + " Lei ", 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('message.total_credit')) + " " + _toDisplayString(_ctx.balantaInNav.SumCredit) + " Lei", 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('message.amount_credit')) + " " + _toDisplayString(_ctx.balantaInNav.SoldCredit) + " Lei", 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('message.total_debit')) + _toDisplayString(_ctx.balantaInNav.SumDebit) + " Lei", 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('message.amount_debit')) + " " + _toDisplayString(_ctx.balantaInNav.SoldDebit) + " Lei", 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.$q.platform.is.mobile)
      ? (_openBlock(), _createBlock(_component_q_btn, {
          key: 1,
          flat: "",
          color: "blue",
          label: _ctx.$t('message.download_balance_pdf'),
          "no-caps": "",
          icon: "picture_as_pdf",
          loading: _ctx.downloadingFile,
          onClick: _ctx.downloadBalantaPdf
        }, null, 8, ["label", "loading", "onClick"]))
      : _createCommentVNode("", true),
    (_ctx.existaBalanta)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_component_q_table, {
            grid: _ctx.$q.platform.is.mobile,
            class: "my-sticky-header-table",
            title: "",
            "rows-per-page-options": [0],
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
            rows: _ctx.balantaInNav.CustLedgerEntry,
            columns: _ctx.columns,
            "visible-columns": _ctx.visibleColumns,
            "row-key": "name",
            "no-data-label": _ctx.$t('message.no_data'),
            flat: "",
            bordered: ""
          }, {
            item: _withCtx((props) => [
              _createVNode(_component_q_card, {
                class: "my_card_3",
                style: _normalizeStyle({'background-color': props.row.isInvoiceOverdue ? '#DAF3F3':'white'})
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_list, { dense: "" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                        return (_openBlock(), _createBlock(_component_q_item, {
                          key: col.name
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(col.label), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_q_item_section, { side: "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(col.value), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["style"])
            ]),
            _: 1
          }, 8, ["grid", "pagination", "rows", "columns", "visible-columns", "no-data-label"])
        ]))
      : _createCommentVNode("", true)
  ]))
}