import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"text-align":"center"} }
const _hoisted_2 = { class: "text-h5" }
const _hoisted_3 = {
  class: "q-pa-md",
  style: {"max-width":"400px","margin":"auto"}
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-xs-12 col-sm-12 col-md-12 q-pa-sm flex_row_center" }
const _hoisted_6 = { class: "col-xs-12 col-sm-12 col-md-12 q-pa-sm" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-xs-12 col-sm-12 col-md-12 q-pa-sm" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-xs-12 col-sm-12 col-md-12 q-pa-sm" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-xs-12 col-sm-12 col-md-12 q-pa-sm" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-xs-12 col-sm-12 col-md-12 q-pa-sm" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-xs-12 col-sm-12 col-md-12 q-pa-sm" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { style: {"margin":"auto"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_spinner_gears = _resolveComponent("q-spinner-gears")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_uploader = _resolveComponent("q-uploader")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('message.my_profile')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_q_form, {
        onSubmit: _ctx.onSubmit,
        class: "q-gutter-md"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_ctx.MyImgProfileString64.length<10)
                ? (_openBlock(), _createBlock(_component_q_icon, {
                    key: 0,
                    name: "photo_camera",
                    class: "text-grey-10",
                    style: {"font-size":"4em","cursor":"pointer"},
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDialogForChangeImgProfile=true))
                  }, {
                    default: _withCtx(() => [
                      (_ctx.$q.platform.is.desktop)
                        ? (_openBlock(), _createBlock(_component_q_tooltip, {
                            key: 0,
                            "transition-show": "scale",
                            "transition-hide": "scale"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('message.click_change_image_profile')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_q_img, {
                    key: 1,
                    src: _ctx.MyImgProfileString64,
                    "spinner-color": "white",
                    style: {"height":"140px","max-width":"150px"},
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialogForChangeImgProfile=true))
                  }, {
                    loading: _withCtx(() => [
                      _createVNode(_component_q_spinner_gears, { color: "white" })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, {
                        "transition-show": "scale",
                        "transition-hide": "scale"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('message.click_change_image_profile')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["src"]))
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_q_input, {
                outlined: "",
                modelValue: _ctx.myProfile.firstName,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.myProfile.firstName) = $event)),
                label: _ctx.$t('message.first_name'),
                "lazy-rules": "",
                rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
              }, null, 8, ["modelValue", "label", "rules"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_q_input, {
                outlined: "",
                modelValue: _ctx.myProfile.lastName,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.myProfile.lastName) = $event)),
                label: _ctx.$t('message.last_name'),
                "lazy-rules": "",
                rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
              }, null, 8, ["modelValue", "label", "rules"])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_q_input, {
                outlined: "",
                readonly: "",
                type: "email",
                modelValue: _ctx.myProfile.email,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.myProfile.email) = $event)),
                label: _ctx.$t('message.email'),
                "lazy-rules": "",
                rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
              }, null, 8, ["modelValue", "label", "rules"])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_q_input, {
                outlined: "",
                readonly: "",
                type: "tel",
                modelValue: _ctx.myProfile.phoneNr,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.myProfile.phoneNr) = $event)),
                label: _ctx.$t('message.phone'),
                "lazy-rules": "",
                rules: [ val => val && val.length > 0 || _ctx.$t('message.enter_field')]
              }, null, 8, ["modelValue", "label", "rules"])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_q_input, {
                onFocus: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showNecessaryInfoForPasswordChange=true)),
                onBlur: _ctx.blurInputNewPassword,
                modelValue: _ctx.myProfile.newPassword,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.myProfile.newPassword) = $event)),
                type: "password",
                outlined: "",
                autogrow: "",
                label: _ctx.$t('message.new_password')
              }, null, 8, ["onBlur", "modelValue", "label"])
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              (_ctx.showNecessaryInfoForPasswordChange)
                ? (_openBlock(), _createBlock(_component_q_input, {
                    key: 0,
                    modelValue: _ctx.myProfile.newPasswordRetyped,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.myProfile.newPasswordRetyped) = $event)),
                    type: "password",
                    outlined: "",
                    autogrow: "",
                    label: _ctx.$t('message.reenter_new_password'),
                    "lazy-rules": "",
                    rules: [_ctx.checkPasswordIfRetypedEqual]
                  }, null, 8, ["modelValue", "label", "rules"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_q_btn, {
                label: _ctx.$t('message.save'),
                type: "submit",
                color: "grey-10"
              }, null, 8, ["label"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.showDialogForChangeImgProfile,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showDialogForChangeImgProfile) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_uploader, {
                  url: _ctx.getUrlForUploadProfileImage,
                  label: _ctx.$t('message.upload_profile_image'),
                  accept: ".jpg, .png, image/*",
                  "auto-upload": "",
                  color: "black",
                  capture: "environment",
                  multiple: _ctx.myBoolFalse,
                  style: {"max-width":"80vw"},
                  onUploaded: _ctx.imgForProfileWasUploaded
                }, null, 8, ["url", "label", "multiple", "onUploaded"])
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_q_btn, {
                  flat: "",
                  label: _ctx.$t('message.drop'),
                  color: "primary"
                }, null, 8, ["label"]), [
                  [_directive_close_popup]
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}