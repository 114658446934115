import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56bd87b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "q-pa-sm ecran-container"
}
const _hoisted_2 = { class: "text-blue" }
const _hoisted_3 = {
  class: "app__color--semigray",
  style: {"padding-top":"2rem","padding-bottom":"2rem"}
}
const _hoisted_4 = { class: "app__label--large" }
const _hoisted_5 = {
  class: "app__label--medium",
  style: {"text-align":"center"}
}
const _hoisted_6 = {
  class: "app__color--gray",
  style: {"font-weight":"bold","font-size":"1.5rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.pidOffer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_q_icon, {
            name: "check",
            color: "blue",
            style: {"font-weight":"bold","font-size":"3rem"}
          }),
          _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t('message.request_sent_successfully')), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('message.in_shortest_term')), 1),
          _createElementVNode("p", _hoisted_4, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.request_id')) + " " + _toDisplayString(_ctx.pidOffer), 1)
          ]),
          _createElementVNode("p", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.$t('message.consult_section')) + " ", 1),
            _createVNode(_component_q_btn, {
              outline: "",
              icon: "shop",
              color: "blue-9",
              label: _ctx.$t('message.requests_and_offers'),
              onClick: _ctx.onGoToOffers
            }, null, 8, ["label", "onClick"])
          ]),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('message.thanks')), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}