import ServiceLogs from "@/services/ServiceLogs";
import { ServiceMail } from "@/services/ServiceMail";
import nomenclatoare from "@/store/nomenclatoare";
import user from "@/store/user";
import { TEmailSubject } from "@/types/TEmailSubject";
import { TUser } from "@/types/TUser";
import { Options, Vue } from "vue-class-component";
import {getModule} from "vuex-module-decorators";


@Options({
    name: "ContactFormular",
    components: {}
})
export default class ContactFormular extends Vue {
    public mailDetails='';
    public userStore = getModule(user); 
    public storeNomenclatoare = getModule(nomenclatoare);
    public subject='';
    public appid = 0;
    
    get user(): TUser {
        return this.userStore.user;
    }

    get emailSubjectOptions(): TEmailSubject[] {
        return this.storeNomenclatoare.nomEmailSubject;
    }


    public onSubmit() {
        const vueInst = this;
        console.log(this.user);
        ServiceMail.sendMailContact(vueInst.mailDetails, vueInst.subject).then(responce => {
            vueInst.mailDetails = '';
            if (responce.status == 'success') {
                vueInst.$q.notify({
                    color: 'blue',
                    textColor: 'white',
                    type: 'info',
                    icon: 'info',
                    position: 'top',
                    timeout: 1000,
                    message: this.$t('message.email_sent') 
                })
            }
        });
    }

    public activated(){
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar(this.$t('message.contact_form'));
        }
        
        const vueInst=this;

        vueInst.appid = 0;
        ServiceLogs.updateTimeSpentPerPage('contact_form', '0', vueInst.appid).then(responce => {
            if(responce.status == 'success'){
                vueInst.appid = responce.appid;
            }
        })
        window.addEventListener('beforeunload', function (event) {
            
            ServiceLogs.updateTimeSpentPerPage('contact_form', '0', vueInst.appid);
        });
    }

    deactivated(): void {
        const vueInst=this;
        ServiceLogs.updateTimeSpentPerPage('contact_form', '0', vueInst.appid);
    }
}