import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import {CONFIG_ENV} from '@/config';
import {ServiceUser} from '@/services/ServiceUser';
import user from '@/store/user';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import { ServiceCerere } from '@/services/ServiceCerere';
import { TCerereHeader } from '@/types/TCerereHeader';
import eventbus from "@/store/eventbus";
import Cerere from '@/pages/Cerere/Cerere.vue';
import { ServiceMail } from '@/services/ServiceMail';
import { ServiceAdminCompanies } from '@/services/ServiceAdminCompanies';
import { TAdminCompany } from '@/types/TAdminCompany';
import { TSalesTeams } from '@/types/TSalesTeams';
import nomenclatoare from '@/store/nomenclatoare';
import { TAdresaLivrare } from '@/types/TAdresaLivrare';
import { ServiceAdreseLivrare } from '@/services/ServiceAdreseLivrare';

type TAdminCompanyAddresses = TAdminCompany & {addresses: TAdresaLivrare[]};

@Options({
    name: "AdminBackOffice",
    components: { Cerere }
})
export default class AdminAdreseLivrare extends Vue {
    public inputSearchArticle='';
    declare public $refs: any;
    public loadingOffers = true;
    public offersHeaders:Array<TCerereHeader>=[];
    public userStore = getModule(user);
    public pagination ={rowsPerPage: 100};
    public showDialogForUploadPdf=false;
    public selectedCerere=0;
    public titleUploadCerere='';
    getUrlForUploadProfileImage:string=CONFIG_ENV.URL_COMPANY_USERS.uploadOfertaPDF+'/'+Math.random();
    
    public dynamicComponent='';
    public dynamicComponentTitle='';
    public dialogViewOferta=false;
    public selectedOfferId = '';
    public selectedOfferSlid = '';
    public selectedOfferDate = '';
    public filterText='';
    public loadingData = true;
    public dialogTransitionShow ='';
    public dialogTransitionHide ='';
    public totalNumberOfPages=1;
    public loadingAddresses=false;
    public currentPage=1;
    public companies:TAdminCompanyAddresses[] = [];
    public EventBusStore = getModule(eventbus);
    public storeNomenclatoare = getModule(nomenclatoare);
    public loadingApollo = false;
    
    public get salesTeams(): TSalesTeams[] {
        return this.storeNomenclatoare.nomSalesTeams;
    }

    public getTeam(id_team: string){
        const team = this.salesTeams.find((team) => team.id_team == id_team);
        console.log(this.salesTeams);
        if(team != undefined) {
            return team.team_name;
        }
        return '';
    }

    
    public columns: any = [];

    public visibleColumns: any = [];

    public filters = {
        id: { value: '', keys: ['id'] },
        data: { value: '', keys: ['data'] },
        nr_items: { value: '', keys: ['nr_items'] },
        user: { value: '', keys: ['user'] },
        cif: { value: '', keys: ['cif'] },
      };

    get user(): TUser {
        return this.userStore.user;
    }

    public refreshFilters(){
        const vueInst = this;
        vueInst.filterText='';
        vueInst.getPageCompanies(1);
    }

    public async getPageCompanies(pageNumber: number) {
        try {
            this.loadingData = true;
            this.companies = [];
    
            const response = await ServiceAdminCompanies.getCompanies2(pageNumber, this.filterText, this.user.id_team);
    
            this.loadingData = false;
    
            if (response.status === 'success') {
                this.totalNumberOfPages = response.totalPages;
    
                const companiesWithAddresses = await Promise.all(
                    response.page.items.map(async (company) => {
                        const addresses = await this.getAddressesCompany(company.cif);
                        return {
                            appid: company.appid,
                            cif: company.cif,
                            denumire: company.denumire,
                            rg_jfc: company.rg_jfc,
                            rg_nr: company.rg_nr,
                            rg_an: company.rg_an,
                            pf_pj: company.pf_pj,
                            id_country: company.id_country,
                            sales_team: this.getTeam(company.sales_team),
                            portal_status: company.portal_status,
                            addresses: addresses,
                            msz: company.msz
                        };
                    })
                );
    
                this.companies = companiesWithAddresses;
            }
        } catch (error) {
            // Handle any errors here
            console.error(error);
        }
    }

    public async getAddressesCompany(cif: string): Promise<TAdresaLivrare[]> {
        try {
            this.loadingAddresses = true;
    
            const responce = await ServiceAdreseLivrare.getAdreseLivrareByCif(cif);
    
            this.loadingAddresses = false;
    
            if (responce.status === 'success') {
                return responce.adrese;
            } else {
                return [];
            }
        } catch (error) {
            // Handle any errors here
            console.error(error);
            return [];
        }
    }

    public approveAddress(address: TAdresaLivrare){

        ServiceAdreseLivrare.saveAdresaLivrare(address).then(responce => {
            if(responce.status == 'success') {
                ServiceAdreseLivrare.deleteNewAdresaLivrare(address).then(responce => {
                    if(responce.status == 'success') {
                        this.getPageCompanies(this.currentPage);
                    }
                });
            }   
        });
        return;
    }

    public deleteAdresa(address: TAdresaLivrare) {
        if(address.isNewAddress == '1') {
            ServiceAdreseLivrare.deleteNewAdresaLivrare(address).then(responce => {
                if(responce.status == 'success') {
                    this.getPageCompanies(this.currentPage);
                }
            });
        } else {
            ServiceAdreseLivrare.deleteAdresaLivrare(address).then(responce => {
                if(responce.status == 'success') {
                    this.getPageCompanies(this.currentPage);
                }
            });
        }
        return;
    }

    public syncronizeApollo() {
        const vueInst = this;
        vueInst.loadingApollo = true;
        ServiceAdreseLivrare.getAdreseApollo().then(() => {
            vueInst.loadingApollo = false;
            vueInst.getPageCompanies(1);
        });
    }

    @Watch('currentPage')
    onCurrentPageChanged() {
        const vueInst=this;
        vueInst.getPageCompanies(vueInst.currentPage);
    }

    @Watch('filterText')
    onFilterTextChanged() {
        const vueInst=this;
        vueInst.getPageCompanies(vueInst.currentPage);
    }

    public activated(){
        this.columns = [
            { name: 'company', label: this.$t('message.name2'), field: 'company', align: 'center', sortable: true },
            { name: 'addresses', label: this.$t('message.delivery_addresses'), field: 'addresses', align: 'left', sortable: true },
            { name: 'action',  label: this.$t('message.options'), field: 'action', align: 'left', sortable: true }
        ]
        this.visibleColumns = [ 'company', 'addresses'];
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar(this.$t('message.admin_delivery_addresses'));
        }
        this.getPageCompanies(1);
    }
}
