import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import user from '@/store/user';
import {CONFIG_ENV, URL_API} from '@/config';
import {getModule} from 'vuex-module-decorators';
import {ServiceCompanies} from '@/services/ServiceCompanies'
import {ServiceAdminUsers} from '@/services/ServiceAdminUsers'
import {TNewUserForAdmin} from '@/types/TNewUserForAdmin';
import {TCompany} from '@/types/TCompany';
import throttle from 'quasar/src/utils/throttle.js';;
import {ServiceAdminCompanies} from "@/services/ServiceAdminCompanies";
import { TAdminCompany } from '@/types/TAdminCompany';
import { TCountry } from '@/types/TCountry';
import nomenclatoare from "@/store/nomenclatoare";
import { TJudet } from '@/types/TJudet';
import { TAdresaLivrare } from '@/types/TAdresaLivrare';
import { ServiceAdreseLivrare } from '@/services/ServiceAdreseLivrare';
import { TSalesTeams } from '@/types/TSalesTeams';

@Options({
    name: "EditNewCompany",
    components: {}
})
export default class EditNewCompany extends Vue {
    @Prop({ default: '0' }) public readonly companyAppid!: string;
    @Prop({ default: '0' }) public readonly userappid!: string;
    @Prop({ default: '' }) public readonly cif!: string;
    @Prop() public fnOnSaveData!: (userid:string)=>void;
    public ajaxLoadingData=true;
    public optionsCountry:TCountry[] = [];
    public company:TAdminCompany={
        appid:0,
        cif:'',
        denumire:'',
        rg_jfc:'',
        rg_nr:'',
        rg_an:'',
        pf_pj:'',
        id_country:'',
        sales_team: '',
        portal_status: '',
        msz: ''
    };
    public sales_team = '';
    public adresa:TAdresaLivrare={
        slid: '0',
        appid: '0',
        cif: '',
        adresaCodJudet: '',
        adresaLocalitate: '',
        adresaAdresa: '',
        adresaCodPostal: 'x',
        tipAdresa: 'baza',
        codTara: '',
        navisionid: '',
        denJudet: '',
        isNewAddress: '0'
    };

    declare public $refs: any;
    public userStore = getModule(user);
    public storeNomenclatoare = getModule(nomenclatoare);
    
    get countryOptions(): TCountry[] {
        return this.storeNomenclatoare.nomCountries;
    }

    get judeteOptions(): TJudet[] {
        return this.storeNomenclatoare.nomJudete;
    }

    get salesTeamsOptions(): TSalesTeams[] {
        return this.storeNomenclatoare.nomSalesTeams;
    }

    public goToView(pRouteViewName: string ) {
        this.$router.push({ name: pRouteViewName })
    }

    public focusOnName(){
        this.$refs.refName.focus();
    }

    public focusOnCif(){
        this.$refs.refCif.focus();
    }

    public focusOnIdCompany(){
        this.$refs.refIdCompany.focus();
    }

    public focusOnJFC(){
        this.$refs.refJFC.focus();
    }
    public focusOnNr(){
        this.$refs.refNr.focus();
    }
    public focusOnAn(){
        this.$refs.refAn.focus();
    }
    public focusOnSalesTeam(){
        this.$refs.refSalesTeam.focus();
    }
    
    public focusCodClient(){
        this.$refs.refCodClient.focus();
    }

    public onSubmit(){
        const vueInst=this;

        vueInst.$q.loading.show()
        vueInst.company.id_country = vueInst.adresa.codTara;
        ServiceAdminCompanies.saveNewCompany(vueInst.company,vueInst.sales_team).then(response=>{
            vueInst.$q.loading.hide()
            if(response.status=='success'){
                vueInst.adresa.cif = vueInst.company.cif;
                ServiceAdreseLivrare.saveAdresaLivrare(vueInst.adresa).then(response=>{
                    console.log(response)
                     if(response.status=='success'){
                            console.log("address saved")
                        }
                 })
         
                vueInst.$q.notify({
                    color: 'teal',
                    textColor: 'white',
                    icon: 'positive',
                    position: 'top',
                    timeout: 2000,
                    message: response.message
                })
                vueInst.fnOnSaveData(response.userappid);
            }
        })
    }

    public created(): void {
        console.log('new company view created')
        console.log(this.cif);
        if(this.cif != '' && this.cif.length > 0) {
            ServiceAdminCompanies.getCompanyByCif(this.cif).then(responce => {
                if (responce.status == 'success'){
                    this.company = responce.company;
                    if(!this.company.rg_jfc || this.company.rg_jfc == 'x'){
                        this.company.rg_jfc = '';
                    } else {
                        this.company.rg_jfc = this.company.rg_jfc + ' ' + this.company.rg_nr + ' ' + this.company.rg_an;
                    }
                    this.sales_team = responce.company.sales_team;
                    ServiceAdreseLivrare.getAdreseLivrareByCif(this.cif).then(response => {
                        if (response.status == 'success') {
                            const addr = response.adrese.filter(address => address.tipAdresa == 'baza');
                            if(addr.length > 0) {
                                this.adresa = addr[0];
                            }
                        }
                    })
                }
            });
        }
    }

    public activated(): void {
        console.log('new company view activated')
    }

    public mounted(): void {
        console.log('new company view mounted')
    }

    public onReset(){
        this.company={
            appid:0,
            cif:'',
            denumire:'',
            rg_jfc:'',
            rg_nr:'',
            rg_an:'',
            pf_pj:'',
            id_country:'',
            sales_team:'',
            portal_status:'',
            msz:''
        };
        this.adresa={
            slid: '0',
            appid: '0',
            cif: '',
            adresaCodJudet: '',
            adresaLocalitate: '',
            adresaAdresa: '',
            adresaCodPostal: 'x',
            tipAdresa: 'baza',
            codTara: '',
            navisionid: '',
            denJudet: '',
            isNewAddress: '0'
        }
    }
}
