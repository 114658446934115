import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import {TArticle} from "@/types/TArticle";

type TArticleStr = {
    appid:string;
    pid:string;
    categoryPid:string;
    lantHierarchyCategoriesRo: string;
    lantHierarchyCategoriesEn: string;
    lantHierarchyCategoriesHu: string;
    code: string;
    name_ro:string;
    name_en:string;
    name_hu:string;
    UMBase:string;
    um1: string;
    um2: string;
    um1ToUm2: number;
    isActive:string;
    withLength: string;
    withWidth: string;
    withThickness: string;
    withDiameter: string;
    withHeight: string;
    withAlloy: string;
    withEyes: string;
    sizeLength:number|null;
    sizeWidth:number|null;
    sizeThickness:number|null;
    sizeDiameter:number|null;
    sizeHeight:number|null;
    sizeAlloy: string|null;
    sizeType: string|null;
}

interface TAjaxResponseSimple {
    status: string;
    message: string;
}


interface TAjaxResponseGetArticle {
    status: string;
    message: string;
    product:TArticleStr;
}

interface TAjaxResponseGetNomProducts {
    status: string;
    message: string;
    products:TArticle[];
    totalPages:number;
    totalItems:number;
}

export class ServiceAdminNomProducts {

    public static async getArticleFromDB(pid:string): Promise<TAjaxResponseGetArticle> {
        const rnd=Math.random();
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.nomProduct}/${pid}/${rnd}`);
        return response.data;
    }

    public static async getNomProductsData(pageNumber: number, filterText:string): Promise<TAjaxResponseGetNomProducts> {
        const rnd=Math.random();
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.nomProduct}/page/10/${pageNumber}/${rnd}`,{ params: { filter_text: filterText } });
        return response.data;
    }

    public static async changeStatusActivInactiv(pid: string, newStatus:string): Promise<TAjaxResponseSimple> {
        const rnd=Math.random();
        const response = await axios.put(`${CONFIG_ENV.URL_ADMIN.nomProduct}/change_status_activ_inactiv/${pid}/${newStatus}`);
        return response.data;
    }

    public static async postProduct(particle: TArticle): Promise<TAjaxResponseSimple> {
        const param = new FormData(); //Create a form object
        param.append('pid', particle.pid);//Add data to the form object via append
        param.append('categoryPid', particle.categoryPid);
        param.append('code', particle.code);
        param.append('name_ro', particle.name_ro);
        param.append('name_en', particle.name_en);
        param.append('name_hu', particle.name_hu);
        param.append('UMBase', particle.UMBase);

        param.append('um1', (particle.um1?particle.um1.toString():'M'));
        param.append('um2', (particle.um2?particle.um2.toString():'0'));
        param.append('um1ToUm2', (particle.um1ToUm2?particle.um1ToUm2.toString():''));

        param.append('isActive', (particle.isActive?'y':'n'));
        param.append('sizeLength', (particle.sizeLength?particle.sizeLength.toString():''));
        param.append('sizeWidth', (particle.sizeWidth?particle.sizeWidth.toString():''));
        param.append('sizeThickness', (particle.sizeThickness?particle.sizeThickness.toString():''));
        param.append('sizeDiameter', (particle.sizeDiameter?particle.sizeDiameter.toString():''));

        param.append('sizeAlloy', (particle.sizeAlloy?particle.sizeAlloy.toString():''));
        param.append('sizeHeight', (particle.sizeHeight?particle.sizeHeight.toString():''));
        param.append('sizeEyes', (particle.sizeEyes?particle.sizeEyes.toString():''));
        param.append('sizeEyes2', (particle.sizeEyes2?particle.sizeEyes2.toString():''));

        const config = {
            headers: {'Content-Type': 'multipart/form-data'}
        };
        let response;
        if(particle.pid){
            response = await axios.post(`${CONFIG_ENV.URL_ADMIN.nomProduct}/${particle.pid}`, param, config);
        }else{
            response = await axios.post(`${CONFIG_ENV.URL_ADMIN.nomProduct}/0`, param, config);
        }

        return response.data;
    }

    public static async getArticlesApollo(): Promise<TAjaxResponseGetNomProducts> {
        const response = await axios.post(`${CONFIG_ENV.URL_ADMIN.get_articles_apollo}`);
        return response.data;
    }

    public static async getArticleApollo(product_code: string): Promise<TAjaxResponseGetNomProducts> {
        const response = await axios.post(`${CONFIG_ENV.URL_ADMIN.get_article_apollo}/${product_code}`);
        return response.data;
    }
}
