import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';

interface TAjaxResponseSimple {
    status: string;
    message: string;
}

interface TAjaxResponseRequest{
    status: string;
    message: string;
    link: string;
    filePath:string;
}

export class ServiceDownload {


    public static async generareOferta(offerId:string): Promise<TAjaxResponseSimple> {
        const response = await axios.get(`${CONFIG_ENV.URL_DOWNLOAD.generare_oferta}/${offerId}`);
        return response.data;
    }

    public static async generareOfertaPdfLink(type:string, offerId:string, lang:string): Promise<TAjaxResponseSimple> {
        const response = await axios.get(`${CONFIG_ENV.URL_DOWNLOAD.generare_oferta_pdf_link}/${type}/${offerId}/${lang}`);
        return response.data;
    }

    public static async generareOfertaPdfLink2(offerId:string, reoffers_count:number): Promise<TAjaxResponseSimple> {
        const response = await axios.get(`${CONFIG_ENV.URL_DOWNLOAD.generare_oferta_pdf_link2}/${offerId}/${reoffers_count}`);
        return response.data;
    }

    public static async generarePDFPrivacy(type:string): Promise<TAjaxResponseSimple> {
        const response = await axios.get(`${CONFIG_ENV.URL_DOWNLOAD.generare_pdf_privacy}/${type}`);
        return response.data;
    }

    public static async generareExcelRequest(id_offer:string): Promise<TAjaxResponseRequest> {
        const response = await axios.get(`${CONFIG_ENV.URL_DOWNLOAD.generare_excel_request}/${id_offer}`);
        return response.data;
    }

    public static async deleteFile(filePath:string): Promise<TAjaxResponseRequest> {
        const params = new URLSearchParams();
        params.set('filePath', filePath);
        const response = await axios.post(`${CONFIG_ENV.URL_DOWNLOAD.delete_file}`, params);
        return response.data;
    }

    public static async checkOfferOnSystem(id_offer:string, reoffers_count:number): Promise<TAjaxResponseSimple> {
        const response = await axios.get(`${CONFIG_ENV.URL_DOWNLOAD.check_offer_on_system}/${id_offer}/${reoffers_count}`);
        return response.data;
    }
}