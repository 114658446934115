<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="about">
    <h3>This is an about page</h3>
    <div
    style="margincolor: #939598;
font-family: Montserrat;
font-size: 28.488px;
font-weight: 500;
letter-spacing: 4px;
line-height: 90px;
word-break: keep-all;
padding:2rem;
"
    >
      Oferind o gamă largă de semifabricate din aluminiu, cupru, alamă, bronz, titanzinc, Color-Metal acoperă nevoile firmelor de prelucrări metalice prin așchiere, confecții metalice, din domenii cum ar fi, automotive, industria alimentară, aeronautică, construcții de mașini, industria publicitară, construcții – renovări etc. Gama largă de produse este completată de servicii profesionale de debitare, înfoliere, dar și suport tehnic.

      Tehnipedia - baza de date tehnică profesionistă pentru ingineri, studenți și alți specialiști din domeniul metalelor neferoase, cuprinde informații detaliate despre: caracteristici mecanice ale aliajelor, toleranțe, prelucrarea tablelor, fișe tehnice, compoziție chimică a aliajelor și nu în ultimul rând diferite calculatoare tehnice.

      Noua divizie de soluții arhitecturale pentru fațade și acoperișuri, respectiv design interior, din cupru și titan-zinc, este o provocare ambițioasă. Clădiri clasice, de patrimoniu au fost renovate cu produse importate de Color-Metal de la KME Germany și elZinc, Spania.

      Divizia de soluții arhitecturale oferă, deci un sistem complex de soluții, începând de la consultanță, o gamă largă de materiale și soluții tehnice în funcție de nevoile beneficiarului. Pentru proiecte urgente putem oferi materiale direct din stocurile importante aflate la dispoziție.
    </div>
  </div>
</template>
