import {Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import user from '@/store/user';
import offers from '@/store/offers';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {ServiceOffer} from '@/services/ServiceOffer';
import {TOfferHeader} from "@/types/TOfferHeader";
import {TOffer} from "@/types/TOffer";
import {TPossibleOfferStatus} from "@/types/TPossibleOfferStatus";
import StatusOferta from "@/components/StatusOferta.vue";
import Cerere from "@/pages/Cerere/Cerere.vue"
import {timeUntilNow, timeUntilFutureDate} from '@/modules/utils'
import {humanStatusOffer,timeDateHuman} from '@/modules/utils'
import eventbus from "@/store/eventbus";
import { ServiceDownload } from '@/services/ServiceDownload';
import VuePdfEmbed from 'vue-pdf-embed';
import { TCertificate } from '@/types/TCertificate';
import { TFactura } from '@/types/TFactura';
import { ServiceInvoice } from '@/services/ServiceInvoice';
import { isSet } from '@vue/shared';
import { ServiceCerere } from '@/services/ServiceCerere';
import { ServiceMail } from '@/services/ServiceMail';
import ServiceLogs from '@/services/ServiceLogs';

@Options({
    name: "Offers",
    components: {StatusOferta, Cerere, VuePdfEmbed}
})
export default class Offers extends Vue {
    @Prop({ default: '0' }) public readonly pidOffer!: string|null;
    public offersHeaders:TOfferHeader[]=[];
    public loadingOffers = true;
    public dialogViewOferta=false;
    public selectedOfferId = '';
    public selectedOfferSlid = '';
    public selectedOfferDate = '';
    public appidToBeCancelled='';
    public appidToBeRecalled='';
    public appidToBeDeleted='';
    public dynamicComponent='';
    public dynamicComponentTitle='';
    public inputSearchOffer = '';
    public dialogTransitionShow ='';
    public dialogTransitionHide ='';
    public userStore = getModule(user);
    public offersStore = getModule(offers);
    public EventBusStore = getModule(eventbus);
    
    public pdfTitle='';
    public fixed=false;
    public pdf='';
    public popupTitle='';
    public isLoadingOffer=false;
    public downloadingFile = false;
    public areMoreCertificates=false;
    public areMoreInvoices=false;
    public areMoreInvoicesCertificate=false;
    public certificates:Array<TCertificate> = [];
    public isLoadingInvoice=false;
    public selectedInvoices:Array<TFactura> = [];
    public widthPdf=300;
    public modifiedOfferText = '';
    public prompt = false;
    public appid = 0;

    get user(): TUser {
        return this.userStore.user;
    }

    get offers():TOffer[]{
        return this.offersStore.offers;
    }

    public changeWitdhPdf(op: string){
        if(op == 'in') {
            if(this.widthPdf > 1500) {return;}
            this.widthPdf *= 1.25; 
        } else {
            if(this.widthPdf < 300) {return;}
            this.widthPdf /= 1.25; 
        }
    }

    public humanStatusOffer(pstatus:TPossibleOfferStatus){
        let result='';
        switch (pstatus) {
            case 'o':
                result = this.$t('message.offer_received');
                break;
            case 'c':
                result = this.$t('message.canceled');
                break;
            case 'p':
                result = this.$t('message.request_sent');
                break;
            case 'k':
                result = this.$t('message.order_sent');
                break;
            case 'm':
                result = this.$t('message.order_modified');
                break;
            default:
                result = "error";
        }
    
        return result;
    }


    public timeDateHuman(pdate:string,pformat:string){
        return timeDateHuman?timeDateHuman(pdate,pformat):'';
    }

    public getOffersFromDB(){
        const vueInst=this;
        vueInst.$q.loading.show();
        ServiceOffer.getOffersFromDB().then(response=>{
            vueInst.$q.loading.hide();
            vueInst.loadingOffers=false;
            if(response.status=='success'){
                vueInst.offersHeaders=JSON.parse(JSON.stringify(response.offers));
            }

        })
    }

    public getOfferByIdFromDB(offerId:string){
        const vueInst=this;
        vueInst.appidToBeRecalled=offerId;
        ServiceOffer.getOffer(offerId).then(response=>{
            if(response.status=='success'){
                const idxInArr=vueInst.offersHeaders.findIndex(offer=>{
                   return offer.offerId==offerId
                })
                const newOffer={
                            dateWhenOfferExpire: response.offerHeader.dateWhenOfferExpire,
                            offerId: response.offerHeader.offerId,
                            offerSlid: response.offerHeader.offerSlid,
                            status: response.offerHeader.status,
                            trackCreationDate: response.offerHeader.trackCreationDate,
                            trackDateAccept: response.offerHeader.trackDateAccept,
                            trackDateOfertare: response.offerHeader.trackDateOfertare

                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                vueInst.offersHeaders[idxInArr]=newOffer;
                vueInst.appidToBeRecalled='';
            }
        })
    }

    private timeUntilNow(pStringDate: string, pformat:string){
        return timeUntilNow(pStringDate,pformat)
    }

    private timeUntilFutureDate(pStringDate: string, pformat:string){
        return timeUntilFutureDate(pStringDate,pformat)
    }

    askCancelOffer(pOfferId:string){
        const vueInst=this;
        vueInst.$q.dialog({
            title: this.$t('message.confirm'),
            message: ` ${pOfferId} ${this.$t('message.cancel_offer2')}`,
            ok: {
                flat:true,
                label: this.$t('message.cancel_offer'),
            },
            cancel: {
                label: this.$t('message.drop'),
                flat:true
            },
            persistent: true
        }).onOk(() => {
            vueInst.appidToBeCancelled=pOfferId;
            ServiceOffer.cancelOffer(pOfferId).then(response=>{
                vueInst.appidToBeCancelled='';
                vueInst.appidToBeDeleted=pOfferId;
                if(response.status=='success'){
                    vueInst.$q.notify({
                        color: 'teal',
                        textColor: 'white',
                        icon: 'positive',
                        position: 'top',
                        timeout: 500,
                        message:  this.$t('message.cancel_request_message')
                    });
                    setTimeout(function(){ 
                        vueInst.eliminaDePeEcranOfertaAnulata(pOfferId); 
                        ServiceMail.sendMailRefuseOffer(pOfferId);
                    }, 1000);
                }
            })
        })
    }

    acceptOffer(pOfferId:string){
        const vueInst=this;
        ServiceCerere.updateCerereStatus(pOfferId, 'k').then(response => {
            if(response.status=='success'){
                console.log('change status success');
                vueInst.getOffersFromDB();
                vueInst.$q.notify({
                    color: 'teal',
                    textColor: 'white',
                    icon: 'positive',
                    position: 'top',
                    timeout: 500,
                    message: this.$t('message.order_sent')
                });
                ServiceMail.sendMailAcceptOffer(pOfferId);
            }
        });
    }

    modifyOffer(pOfferId:string) {
        const vueInst=this;
        vueInst.prompt = true;
        vueInst.modifiedOfferText = '';
        vueInst.selectedOfferId = pOfferId;
    }

    sendModifyOffer(pOfferId:string){
        const vueInst=this;
        vueInst.selectedOfferId = '';
        ServiceCerere.updateCerereStatus(pOfferId, 'm').then(response => {
            if(response.status=='success'){
                console.log('change status success');
                vueInst.getOffersFromDB();
                vueInst.$q.notify({
                    color: 'teal',
                    textColor: 'white',
                    icon: 'positive',
                    position: 'top',
                    timeout: 500,
                    message: this.$t('message.order_modified')
                });
                ServiceMail.sendMailModifyOffer(pOfferId, vueInst.modifiedOfferText)
            }
        });

    }

    public closeCurrentView(){
        const vueInst=this;
        //vueInst.dialogViewOferta = false;
        vueInst.EventBusStore.set_event({name:'closeCurrentView', params:null});
    }

    public closeFormViewOferta(needRefresh:boolean){
        const vueInst=this;
        vueInst.dialogViewOferta = false;
        if(needRefresh){
            ServiceOffer.getOffer(vueInst.selectedOfferId).then(response=>{
                if(response.status=='success'){
                    //extend(true, vueInst.offerHeader,  response.offerHeader);
                   const indexToBeReplaced= vueInst.offersHeaders.findIndex((poffer,pindex)=>{
                        return poffer.offerId==response.offerHeader.offerId;
                    })
                    vueInst.offersHeaders[indexToBeReplaced]=response.offerHeader;
                }
            })
        }
        vueInst.selectedOfferId='';
    }

    public onOpenOferta(pOfferId:string|null, pofferSlid:string|null , pofferDate:string|null){
        if(pOfferId && pofferSlid && pofferDate) {
            this.dynamicComponent='Oferta';
            this.dynamicComponentTitle=this.$t('message.offer') ;
            this.selectedOfferId = pOfferId;
            this.selectedOfferSlid = pofferSlid;
            this.selectedOfferDate = pofferDate;

            if (this.$q.platform.is.mobile) {
                this.dialogTransitionShow = 'slide-right';
                this.dialogTransitionHide = 'slide-left';
            } else {
                this.dialogTransitionShow = 'slide-right';
                this.dialogTransitionHide = 'slide-left';
            }
            this.dialogViewOferta = true;
        }
    }

    public onOpenCerere(pOfferId:string|null, pofferDate:string|null){
        if(pOfferId && pofferDate) {
            this.dynamicComponent='Cerere';
            this.dynamicComponentTitle=this.$t('message.request') ;
            this.selectedOfferId = pOfferId;
            this.selectedOfferSlid = pOfferId;//ca sa nu mai fac variabile separate
            this.selectedOfferDate = pofferDate;
            if (this.$q.platform.is.mobile) {
            this.dialogTransitionShow = 'slide-right';
            this.dialogTransitionHide = 'slide-left';
        } else {
            this.dialogTransitionShow = 'slide-right';
            this.dialogTransitionHide = 'slide-left';
        }
        this.dialogViewOferta = true;
        }
    }

    public eliminaDePeEcranOfertaAnulata(pOfferId:string){
        const vueInst=this;
        let indexToDelete=-1;
        vueInst.offersHeaders.forEach((offer,pindex) => {
            if(offer.offerId == pOfferId){
                indexToDelete=pindex;
            }
        })
        vueInst.offersHeaders.splice(indexToDelete,1);
        vueInst.appidToBeDeleted='';
    }

    @Watch('EventBusStore.event.eventId')
    onEventIdChanged() {
        const vueInst=this;
        console.log('in Offers eventId is changed, vueInst.EventBusStore.event=%o',vueInst.EventBusStore.event)
        if(vueInst.EventBusStore.event.name=='eventCloseDialogViewOffer'){
            if(this.$route.name=='Offers') {
                vueInst.dialogViewOferta=false;
            }
        }
    }

    public downloadPDF() {
        const vueInst = this;
        const linkSource = vueInst.pdf;
        const downloadLink = document.createElement("a");
        const fileName = vueInst.pdfTitle;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    public generareOferta(offerSlid: string, reoffers_count: number){
        const vueInst = this;
        vueInst.pdf = '';
        vueInst.pdfTitle = '';
        vueInst.popupTitle = '';
        vueInst.isLoadingOffer= true;  
        vueInst.fixed = true;  
        ServiceDownload.generareOfertaPdfLink2(offerSlid, reoffers_count).then(response => {
            vueInst.isLoadingOffer = false;
            if(response.status == 'success') {
                const pathPdf = response.message;
                vueInst.pdfTitle = 'Offer_for_resuest_'+offerSlid+'_'+this.userStore.user.companyName+'.pdf'
                vueInst.pdf = 'data:application/pdf;base64,'+ pathPdf +'#title='+ vueInst.pdfTitle;
                if(reoffers_count > 0) {
                    vueInst.popupTitle = this.$t('message.offer') + ' ' + offerSlid + ' ' + reoffers_count;
                } else {
                    vueInst.popupTitle = this.$t('message.offer') +' ' + offerSlid;
                }
            } else {
                vueInst.fixed = false;  
            }
        });
        
    }

    public generareOfertaPDFRO(offerSlid: string){
        const vueInst = this;
        vueInst.pdf = '';
        vueInst.pdfTitle = '';
        vueInst.popupTitle = '';
        vueInst.isLoadingOffer= true;  
        vueInst.fixed = true;  
        ServiceDownload.generareOfertaPdfLink('confirmare', offerSlid, 'ro').then(response => {
            vueInst.isLoadingOffer = false;
            if(response.status == 'success') {
                const pathPdf = response.message;
                vueInst.pdfTitle = 'Confirmare_'+offerSlid+'_'+this.userStore.user.companyName+'.pdf'
                vueInst.pdf = 'data:application/pdf;base64,'+ pathPdf +'#title='+ vueInst.pdfTitle;
                vueInst.popupTitle = this.$t('message.confirmation') +' ' + offerSlid;
            }
        });
        
    }

    public generareOfertaPDFENG(offerSlid: string){
        const vueInst = this;
        vueInst.pdf = '';
        vueInst.pdfTitle = '';
        vueInst.popupTitle = '';
        vueInst.isLoadingOffer= true;   
        vueInst.fixed = true;
        ServiceDownload.generareOfertaPdfLink('confirmare',offerSlid, 'eng').then(response => {
            vueInst.isLoadingOffer = false;
            if(response.status == 'success') {
                const pathPdf = response.message;
                vueInst.pdfTitle = 'Confirmation_'+offerSlid+'_'+this.userStore.user.companyName+'.pdf'
                vueInst.pdf = 'data:application/pdf;base64,'+ pathPdf +'#title='+ vueInst.pdfTitle;
                vueInst.popupTitle = this.$t('message.confirmation') + ' ' + offerSlid;
            }
        });
        
    }

    public download_certificate(bill_nr: string){
        const vueInst=this;
        console.log(bill_nr);
        vueInst.pdf = '';
        vueInst.pdfTitle = '';
        vueInst.popupTitle = '';     
        ServiceInvoice.downloadCertificate(bill_nr).then(response=>{
            
            if(response.status=='success') { 
                console.log(response);
                if(response.countCertificates > 0) {
                    vueInst.areMoreCertificates = true;
                    console.log(response.articles);
                    vueInst.certificates = response.articles;
                    vueInst.popupTitle = this.$t('message.certificates') + ' ' +this.$t('message.invoice') + ' ' + bill_nr;
                } else {
                    if(isSet(response.message)) {    
                        vueInst.fixed = true;  
                        vueInst.pdfTitle = 'Certificate_'+bill_nr+'.pdf';
                        vueInst.pdf = 'data:application/pdf;base64,'+ response.message +'#title='+vueInst.pdfTitle;
                        vueInst.popupTitle = this.$t('message.certificate') +' '+bill_nr;
                    } else {
                        vueInst.$q.notify({
                            color: 'red',
                            textColor: 'white',
                            type: 'negative',
                            icon: 'error',
                            position: 'top',
                            timeout: 1000,
                            message: this.$t('message.no_certificates')
                        })
                    }
                }
            }

        })
    }

    public download_certificate_2(index: number){
        const vueInst=this;
        const certificat = vueInst.certificates[index];
        vueInst.pdf = '';
        vueInst.pdfTitle = '';
        vueInst.popupTitle = '';
        vueInst.isLoadingInvoice = true;     
        vueInst.fixed = true;  
        vueInst.pdfTitle = 'Certificate_'+certificat.ItemNo+'.pdf';
        vueInst.pdf = 'data:application/pdf;base64,'+ certificat.Certificate+'#title='+vueInst.pdfTitle;
        vueInst.popupTitle = this.$t('message.certificate')+' '+certificat.ItemNo;
        vueInst.isLoadingInvoice = false;   
    }

    public generareCertificate(offer: TOfferHeader){
        const vueInst = this;
        vueInst.selectedOfferSlid = '';
        if(offer.invoices != null && offer.invoices.length > 0) {
            if(offer.invoices.length == 1) {
                vueInst.download_certificate(offer.invoices[0].id);
            } else {
                vueInst.areMoreInvoicesCertificate = true;
                vueInst.selectedInvoices = offer.invoices;
                vueInst.selectedOfferSlid = offer.offerSlid;
            }
        } else {
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                icon: 'error',
                position: 'top',
                timeout: 1000,
                message: this.$t('message.no_certificates_offer')
            })
        } 
    }

    public generateFacturi(offer: TOfferHeader){
        const vueInst = this;
        if(offer.invoices != null && offer.invoices.length > 0) {
            vueInst.pdf = '';
            vueInst.pdfTitle = '';
            vueInst.popupTitle = '';
            vueInst.isLoadingOffer= true;
            vueInst.selectedInvoices = offer.invoices;
            if(offer.invoices.length > 1) {
                vueInst.areMoreInvoices = true;
                vueInst.popupTitle = this.$t('message.invoice') + ' ' +this.$t('message.offer') + ' ' + offer.offerSlid; 
            } else {
                vueInst.fixed = true;
                const bill_nr = offer.invoices[0].id;
                vueInst.popupTitle = this.$t('message.invoice') + ' '+ bill_nr;  
                ServiceInvoice.downloadInvoice(bill_nr).then(response=>{
                    vueInst.isLoadingOffer = false; 
                    if(response.status=='success') { 
                            vueInst.pdfTitle = 'Invoice_'+bill_nr+'.pdf';
                            vueInst.pdf = 'data:application/pdf;base64,'+ response.message +'#title='+vueInst.pdfTitle;
                    }

                });
            }
        } else {
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                icon: 'error',
                position: 'top',
                timeout: 1000,
                message: this.$t('message.no_invoices_offer')
            })
        }

    }

    public download_invoice(bill_nr: string){
        const vueInst=this;
        console.log(bill_nr);
        vueInst.fixed = true;
        vueInst.pdf = '';
        vueInst.pdfTitle = '';
        vueInst.popupTitle = this.$t('message.invoice') +' '+ bill_nr;
        vueInst.isLoadingOffer = true;      
        ServiceInvoice.downloadInvoice(bill_nr).then(response=>{
            vueInst.isLoadingOffer = false; 
            if(response.status=='success') { 
                    vueInst.pdfTitle = 'Invoice_'+bill_nr+'.pdf';
                    vueInst.pdf = 'data:application/pdf;base64,'+ response.message +'#title='+vueInst.pdfTitle;
            }

        })
    }


    public activated(){
        this.loadingOffers=true;
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar(this.$t('message.requests_and_offers'));
        }
        this.getOffersFromDB();
        const vueInst = this;
        vueInst.appid = 0;
        ServiceLogs.updateTimeSpentPerPage('offers', '0', vueInst.appid).then(responce => {
            if(responce.status == 'success'){
                vueInst.appid = responce.appid;
            }
        })
        window.addEventListener('beforeunload', function (event) {
            
            ServiceLogs.updateTimeSpentPerPage('offers', '0', vueInst.appid);
        });
    }

    deactivated(): void {
        const vueInst=this;
        ServiceLogs.updateTimeSpentPerPage('offers', '0', vueInst.appid);
    }

}
