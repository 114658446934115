import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import {TOfferHeader} from "@/types/TOfferHeader";
import {TProductBasket} from "@/types/TProductBasket";
import {TArticleOfferedFromSales} from "@/types/TArticleOfferedFromSales";
import { TCerereHeader } from '@/types/TCerereHeader';

interface TAjaxResponseSimple {
    status: string;
    message: string;
}


type TAjaxResponseSendForOffer={
    status: string;
    message: string;
    offerId: string;
}


type TAjaxResponseGetOffers={
    status: string;
    message: string;
    offers: TOfferHeader[];
}

type TAjaxResponseGetCereri={
    status: string;
    message: string;
    offers: TCerereHeader[];
}

type TAjaxResponseGetOffer={
    status: string;
    message: string;
    offerHeader: TOfferHeader[];
    products: TProductBasket[]
}

export class ServiceCerere {
    public static async getCerere(offerId:string): Promise<TAjaxResponseGetOffer> {
        const rnd=Math.random();
        const response = await axios.get(`${CONFIG_ENV.URL_CERERE.cerere}/${offerId}`);
        return response.data;
    }
    public static async getCereri(searchInput: string, searchCompany: string, searchAgent: string):  Promise<TAjaxResponseGetCereri> {
        const params = new URLSearchParams();
        params.set('searchInput',searchInput);
        params.set('searchCompany',searchCompany);
        params.set('searchAgent',searchAgent);
        const response = await axios.post(`${CONFIG_ENV.URL_CERERE.cereri}`, params);
        return response.data;
    }
    public static async updateCerereStatus(id_cerere: string, status: string):  Promise<TAjaxResponseSimple> {
        const params = new URLSearchParams();
        params.set('status',status);
        const response = await axios.post(`${CONFIG_ENV.URL_CERERE.update_cerere_status}/${id_cerere}`, params);
        return response.data;
    }

    public static async increaseModifiedNumber(id_cerere: string):  Promise<TAjaxResponseSimple> {
        const response = await axios.post(`${CONFIG_ENV.URL_CERERE.increase_modified_number}/${id_cerere}`);
        return response.data;
    }
}
