import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73637771"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "q-pa-sm ecran-container" }
const _hoisted_2 = { style: {"display":"flex","justify-content":"flex-start","min-width":"70vw","padding-bottom":"2rem"} }
const _hoisted_3 = { class: "app__page__title__container" }
const _hoisted_4 = { style: {"white-space":"nowrap","font-size":"2rem"} }
const _hoisted_5 = { style: {"min-width":"70vw"} }
const _hoisted_6 = { class: "parametru" }
const _hoisted_7 = { class: "app__title--small" }
const _hoisted_8 = { class: "app__label--large" }
const _hoisted_9 = { class: "parametru" }
const _hoisted_10 = { class: "app__title--small" }
const _hoisted_11 = { class: "app__label--large" }
const _hoisted_12 = { class: "parametru" }
const _hoisted_13 = { class: "app__title--small" }
const _hoisted_14 = { class: "app__label--large" }
const _hoisted_15 = { class: "parametru" }
const _hoisted_16 = { class: "app__title--small" }
const _hoisted_17 = { class: "app__label--large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_q_icon, {
            name: "account_balance",
            style: {"font-size":"2rem","position":"relative","top":"-4px"},
            color: "primary"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('message.company_data')), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('message.name2')), 1),
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.denumire), 1)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('message.cui')), 1),
        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.cui), 1)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('message.reg_com')), 1),
        _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.regCom), 1)
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('message.base_address')), 1),
        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.adresaBaza), 1)
      ])
    ])
  ]))
}