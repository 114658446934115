import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import extend from 'quasar/src/utils/extend.js';
import user from '@/store/user';
import {URL_API} from '@/config';
import {getModule} from 'vuex-module-decorators';
import {TCompanyUser} from '@/types/TCompanyUser';
import {TUser} from "@/types/TUser";
import {ServiceCompanyUsers} from "@/services/ServiceCompanyUsers"
import ContacteleMeleColorMetal from "@/components/ContacteleMeleColorMetal.vue";
import {CONFIG_ENV} from '@/config';
import StatusOferta from "*.vue";
import Oferta from "@/pages/Oferta/Oferta.vue";
import Cerere from "@/pages/Cerere/Cerere.vue";
import ServiceLogs from '@/services/ServiceLogs';

@Options({
    name: "UsersCompany",
    components: {ContacteleMeleColorMetal}
})
export default class UsersCompany extends Vue {
    @Prop({ default: '0' }) public readonly userid!: string;
    public users:TCompanyUser[] = [];
    public loadingData = true;
    public dialogUser = false;
    public dialogTransitionShow ='';
    public dialogTransitionHide ='';
    public urlUserImgProfileByAppid = CONFIG_ENV.URL_ADMIN.users+'/img_user_profile_jpg_by_user_appid'
    declare public $refs: any;
    public appid = 0;

    public userStore = getModule(user);


    public get user(): TUser {
        return this.userStore.user;
    }


    public getUsers(){
        const vueInst = this;
        vueInst.loadingData=true;
        vueInst.users=[];
        ServiceCompanyUsers.getAllCompanyUsers().then(response=>{
            vueInst.loadingData=false;
            if(response.status=='success'){
                vueInst.users=response.users.map((user:TCompanyUser)=> {return {
                    appid: user.appid,
                    userid: user.userid,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    functie: user.functie,
                    phoneNr: user.phoneNr,
                    isDisabled: user.isDisabled,
                    urlUserImgProfileByAppid:vueInst.urlUserImgProfileByAppid+'/'+user.appid
                }});
            }
        })
    }

    public activated(){
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar(this.$t('message.users'));
        }
        const vueInst = this;
        vueInst.appid = 0;
        
        vueInst.getUsers();
        ServiceLogs.updateTimeSpentPerPage('users', '0', vueInst.appid).then(responce => {
            if(responce.status == 'success'){
                vueInst.appid = responce.appid;
            }
        })
        window.addEventListener('beforeunload', function (event) {
            
            ServiceLogs.updateTimeSpentPerPage('users', '0', vueInst.appid);
        });
    }

    public created(): void {
        const vueInst = this;
        vueInst.getUsers();
    }

    deactivated(): void {
        const vueInst=this;
        ServiceLogs.updateTimeSpentPerPage('users', '0', vueInst.appid);
    }

}
