import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import date from 'quasar/src/utils/date.js';
import {CONFIG_ENV} from '@/config';
import user from '@/store/user';
import basket from '@/store/basket';
import offers from '@/store/offers';
import favorites from '@/store/favorites';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TProductBasket} from "@/types/TProductBasket";
import {TOffer} from "@/types/TOffer";
import {ServiceFavorites} from "@/services/ServiceFavorites";
import {ServiceBasket} from "@/services/ServiceBasket";
import {ServiceOffer} from '@/services/ServiceOffer';
import {TAdresaLivrare} from "@/types/TAdresaLivrare";
import {ServiceAdreseLivrare} from "@/services/ServiceAdreseLivrare";
import NomEditAdresaLivrare from '@/components/NomEditAdresaLivrare/NomEditAdresaLivrare.vue';
import EventsBus from "@/store/eventbus";
import EditQuantityArticleCerere from '@/components/EditQuantityArticleCerere/EditQuantityArticleCerere';
import { ServiceMail } from '@/services/ServiceMail';
import ServiceLogs from '@/services/ServiceLogs';

type TProductBasketMarkedFavorites = TProductBasket & {isInFavorite:boolean};
@Options({
    name: "MyShoppingCart",
    components: { NomEditAdresaLivrare, EditQuantityArticleCerere }
})
export default class MyShoppingCart extends Vue {
    public loading=false;
    public isMyShoppingCartActivated=false;
    public urlToJPG = CONFIG_ENV.URL_CATEGORY.getJPG;
    public storeBasket = getModule(basket);
    public storeFavorites = getModule(favorites);
    public appidToBeRemovedFromBasket = '';
    public appidToBeTogglesWithFavorites = '';
    public inputFreeTextComments='';
    public termenCerere='';
    public nrComandaCerere='';
    public slidAdresaLivrare='';
    public myLocale=CONFIG_ENV.myLocale;
    public adrese:TAdresaLivrare[]=[]; 
    public dialogAdresa=false;
    public selectedAdresa:TAdresaLivrare={
        slid:'0',
        appid: '',
        cif: '',
        adresaCodJudet: '',
        adresaLocalitate: '',
        adresaAdresa: '',
        adresaCodPostal: 'x',
        tipAdresa: 'livrare',
        codTara: '',
        navisionid: '',
        denJudet: '',
        isNewAddress: '0'
    };
    public dialogTransitionShow ='';
    public dialogTransitionHide ='';
    public isAdreseLivrareActivated=false;

    declare public $refs: any;
    public userStore = getModule(user);
    public offersStore = getModule(offers);
    public EventBusStore = getModule(EventsBus);
    public appid = 0;

    get user(): TUser {
        return this.userStore.user;
    }

    get basket():TProductBasketMarkedFavorites[]{
        const vueInst=this;
        const result:TProductBasketMarkedFavorites[]=[];
        this.storeBasket.basket.forEach(product=>{
            result.push({
                appid:product.appid,
                productPid:product.productPid,
                categoryPid: product.categoryPid,
                categoryName: product.categoryName,
                productCode:product.productCode,
                productNameRo: product.productNameRo,
                productNameEn: product.productNameEn,
                productNameHu: product.productNameHu,
                qBuc: product.qBuc,
                q_um_base: product.q_um_base,
                qUm1 :product.qUm1,
                qUm2: product.qUm2,
                um1:product.um1,
                um2:product.um2,
                um1_to_um2:product.um1_to_um2,
                l: product.l,
                w: product.w,
                t: product.t,
                d: product.d,
                h: product.h,
                a: product.a,
                k: product.k,
                e: product.e,
                dorescDebitare: product.dorescDebitare,
                cuttingLength: product.cuttingLength,
                cuttingWidth: product.cuttingWidth,
                enumPlacaBara:product.enumPlacaBara,
                densitate:product.densitate,
                isInFavorite:vueInst.storeFavorites.favorites.some(favorite=>{return favorite.productCode==product.productCode}),
                observatii:product.observatii,
                nr_ord:product.nr_ord,
                tip_um:product.tip_um,
                price_eur: product.price_eur,
                price_huf: product.price_huf
            });
        })
        return result;
    }


    public removeArticleFromShoppingCart(product:TProductBasket){
        const vueInst=this;
        vueInst.appidToBeRemovedFromBasket=product.appid;
        ServiceBasket.removeProductFromBasket(product.appid).then(response=>{
            if(response.status=='success') {
                vueInst.storeBasket.remove_product_from_basket(product);
            }
        });

    }

    public toggleArticleInFavorites(item:TProductBasketMarkedFavorites) {
        const vueInst = this;
        vueInst.appidToBeTogglesWithFavorites=item.appid;
        console.log(item);
        if (item.isInFavorite) {
            ServiceFavorites.removeProductFromFavorites(item.productCode).then(response=>{
                if(response.status=='success'){
                    vueInst.storeFavorites.remove_product_from_favorites(item);
                    vueInst.appidToBeTogglesWithFavorites='';
                    vueInst.$q.notify({
                        color: 'red-6',
                        textColor: 'white',
                        icon: 'favorite',
                        position: 'top',
                        timeout: 500,
                        message: this.$t('message.article_removed_from_favorite')
                    })
                }
            });
        } else {
        ServiceFavorites.identifyArticleInDBAndPutIntoFavorites(item.productCode, item.l, item.w, item.t, item.d, item.h, item.a, item.k, item.um1, item.um2, item.qUm1, item.qUm2, item.dorescDebitare, item.observatii)
            .then(response => {
                vueInst.appidToBeTogglesWithFavorites='';
                if (response.status == 'success') {
                    const productForFavorite: TProductBasket = {
                        appid: response.appid,
                        productPid: response.productPid,
                        categoryName: response.categoryName,
                        categoryPid: response.categoryPid,
                        productCode: response.productCode,
                        productNameRo: response.productNameRo,
                        productNameEn: response.productNameEn,
                        productNameHu: response.productNameHu,
                        qBuc: item.qBuc,
                        q_um_base: item.qBuc,
                        qUm1: item.qUm1,
                        qUm2: item.qUm2,
                        um1:item.um1,
                        um2:item.um2,
                        um1_to_um2:item.um1_to_um2,
                        l: item.l,
                        w: item.w,
                        t: item.t,
                        d: item.d,
                        h: item.h,
                        a: item.a,
                        k: item.k,
                        e: item.e,
                        dorescDebitare: item.dorescDebitare,
                        cuttingLength: item.cuttingLength,
                        cuttingWidth: item.cuttingWidth,
                        enumPlacaBara:item.enumPlacaBara,
                        densitate: item.densitate,
                        observatii:item.observatii,
                        nr_ord:item.nr_ord,
                        tip_um:item.tip_um,
                        price_eur: item.price_eur,
                        price_huf: item.price_huf
                    }
                    vueInst.storeFavorites.push_product_to_favorites(productForFavorite);
                    vueInst.$q.notify({
                        color: 'red-6',
                        textColor: 'white',
                        icon: 'favorite',
                        position: 'top',
                        timeout: 500,
                        message: this.$t('message.article_added_to_favorite')
                    })
                }
            })
        }
    }

    public optionsDataViitor (date:string) {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();

        const todayFmt = yyyy + '/' + mm + '/' + dd;
        return date > todayFmt
    }

    public trySendBasketForAnOffer(){
        const vueInst=this;
        if(vueInst.slidAdresaLivrare.toString().length==0){
            vueInst.$q.notify({
                color: 'orange-9',
                textColor: 'white',
                icon: 'shopping_cart',
                position: 'top',
                timeout: 1000,
                message: this.$t('message.insert_delivery_address')
            })
            return false;
        }
        vueInst.setSelectedAdresa(vueInst.slidAdresaLivrare);
        if(vueInst.selectedAdresa.isNewAddress == '1') {

            vueInst.$q.dialog({
                title: this.$t('message.confirm'),
                message: this.$t('message.address_waiting_for_aproval'),
                cancel: true,
                persistent: true
            }).onOk(() => {
                vueInst.$q.loading.show();
                vueInst.sendBasketForAnOffer();
            })
            return false;
        } 
        else{
            vueInst.$q.loading.show();
            vueInst.sendBasketForAnOffer();
        }
    }

    public sendBasketForAnOffer() {
        const vueInst=this;
        const pidOffer=Math.floor(Math.random() * 10000000).toString();
        ServiceOffer.sendBasketForAnOffer(vueInst.storeBasket.basket,vueInst.nrComandaCerere,vueInst.termenCerere,vueInst.slidAdresaLivrare,vueInst.inputFreeTextComments).then(response=>{
            vueInst.$q.loading.hide();
            if(response.status=='success'){
                //vueInst.offersStore.push_offer(offer);
                vueInst.storeBasket.clear_basket();
                vueInst.termenCerere='';
                vueInst.nrComandaCerere='';
                vueInst.slidAdresaLivrare='';
                vueInst.inputFreeTextComments='';
                vueInst.$router.push({name: 'FirstPageAfterPushOffer',  params: { pidOffer:response.offerId }});
                ServiceMail.sendMailNewRequest(response.offerId);
            }
        }).catch((error) => {
            vueInst.$q.loading.hide();
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                icon: 'error',
                position: 'top',
                timeout: 3000,
                message: error.toString()
            })
        });
    }

    public totalPricePerItem(item: TProductBasket){
        return Number(item.price_huf * item.qUm1).toFixed(4);
    }
    
    public getTotalPrice(){
        const vueInst = this;
        let total = 0;
        vueInst.storeBasket.basket.forEach(item => {
            total += item.price_huf * item.qUm1;
        });
        return Number(total).toFixed(4);
    }

    public getTotalQuantity() {
        const vueInst = this;
        let total = 0;
        vueInst.storeBasket.basket.forEach(item => {
            total += item.qUm2 ? item.qUm2 : 0;
        });
        return Number(total).toFixed(2);
    }

    public moveUP(index: number){
        const vueInst=this;
        if (index > 0) {
            const currentItem = vueInst.storeBasket.basket[index];
            currentItem.nr_ord = index-1;
            const newItem = vueInst.storeBasket.basket[index-1];
            newItem.nr_ord = index;
            vueInst.storeBasket.basket.splice(index, 1,newItem);
            vueInst.storeBasket.basket.splice(index-1, 1, currentItem);
        }
    }

    public moveDown(index: number){
        const vueInst=this;
        if (index < vueInst.storeBasket.basket.length-1) {
            const currentItem = vueInst.storeBasket.basket[index];
            currentItem.nr_ord = index+1;
            const newItem = vueInst.storeBasket.basket[index+1];
            newItem.nr_ord = index;
            vueInst.storeBasket.basket.splice(index, 1,newItem);
            vueInst.storeBasket.basket.splice(index+1, 1, currentItem);
        }
    }

    public setSelectedAdresa(slid: string){
        const vueInst = this;
        vueInst.adrese.forEach(adresa => {
            if(adresa.slid == slid) {
                vueInst.selectedAdresa = adresa;
            }
        });
        console.log(vueInst.selectedAdresa);
    }

    public activated(){
        const vueInst=this;
        this.isMyShoppingCartActivated=true;
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar(this.$t('message.my_basket'));
        }
        vueInst.getAdreseLivrare();
        vueInst.appid = 0;
        ServiceLogs.updateTimeSpentPerPage('my_basket', '0', vueInst.appid).then(responce => {
            if(responce.status == 'success'){
                vueInst.appid = responce.appid;
            }
        })
        window.addEventListener('beforeunload', function (event) {
            
            ServiceLogs.updateTimeSpentPerPage('my_basket', '0', vueInst.appid);
        });
    }

    public getAdreseLivrare(){
        const vueInst=this;
        ServiceAdreseLivrare.getAdreseLivrare().then(response=>{
            if(response.status=='success'){
                vueInst.adrese=JSON.parse(JSON.stringify(response.adrese));
                vueInst.slidAdresaLivrare=vueInst.adrese[0].slid;
                // if(vueInst.adrese.length==1){
                    
                // }
            }
        })
    }

    public onEditItem(item:TProductBasket, index:number) {
        const vueInst=this;
        console.log('edit item=%o',item);

        vueInst.storeBasket.basket.splice(index,1, {...item});
        console.log('Now, products=%o',vueInst.storeBasket.basket)
    }

    public printItem(item:TProductBasket, index:number){
        console.log(item);
        console.log(index);
    }

    private removeArticle(item:TProductBasket,index:number) {
        const vueInst=this;
        vueInst.storeBasket.basket.splice(index,1);
    }

    public onOpenFormAddNewAdresa(){
        //this.selectedUser=puser
        const vueInst=this;
        vueInst.selectedAdresa={
            slid:'0',
            appid: '',
            cif: '',
            adresaCodJudet: '',
            adresaLocalitate: '',
            adresaAdresa: '',
            adresaCodPostal: 'x',
            tipAdresa: 'livrare',
            codTara: '',
            navisionid: '',
            denJudet: '',
            isNewAddress: '1'
        };
        if(vueInst.$q.platform.is.mobile){
            vueInst.dialogTransitionShow ='slide-right';
            vueInst.dialogTransitionHide ='slide-left';
        }else{
            vueInst.dialogTransitionShow ='slide-right';
            vueInst.dialogTransitionHide ='slide-left';
        }
        // console.log(this.selectedAdresa);
        vueInst.dialogAdresa=true;
    }

    public closeFormEditAdresaLivrare(needRefresh:boolean){
        const vueInst=this;
        vueInst.dialogAdresa=false;
        if(needRefresh){
            vueInst.getAdreseLivrare();
        }
    }

    @Watch('EventBusStore.event.eventId')
    onEventIdChanged() {
        const vueInst=this;
        if(vueInst.EventBusStore.event.name=='eventClickAddBtn'){
            if(vueInst.isAdreseLivrareActivated){
                vueInst.onOpenFormAddNewAdresa();
            } else {
                vueInst.closeFormEditAdresaLivrare(true);
            }
        }
    }

    public deactivated() {
        this.isMyShoppingCartActivated = false;
        this.isAdreseLivrareActivated = false;
        const vueInst=this;
        ServiceLogs.updateTimeSpentPerPage('my_basket', '0', vueInst.appid);
    }
}
