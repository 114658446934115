import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71cf69c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "q-pa-lg ecran-container" }
const _hoisted_2 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_3 = {
  key: 0,
  class: "app__page__title__container"
}
const _hoisted_4 = {
  class: "app__page__title",
  style: {"white-space":"nowrap"}
}
const _hoisted_5 = {
  style: {"size":"1.5rem"},
  class: "q-pa-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$q.platform.is.desktop)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_q_icon, {
                name: "notifications_active",
                style: {"font-size":"2rem","top":"-3px"},
                color: "primary"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('message.send_notification')), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", null, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('message.notification_description')) + ":", 1)
      ])
    ]),
    _createElementVNode("div", {
      class: "q-pa-md",
      style: _normalizeStyle(_ctx.$q.platform.is.mobile ? 'width: 100%;' : 'width:50%')
    }, [
      _createVNode(_component_q_input, {
        maxlength: "2000",
        type: "textarea",
        counter: "",
        class: "input--desire",
        outlined: "",
        modelValue: _ctx.subject,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.subject) = $event)),
        placeholder: _ctx.$t('message.notification_description'),
        color: "blue-grey-7",
        rows: "10"
      }, null, 8, ["modelValue", "placeholder"]),
      _createVNode(_component_q_btn, {
        disable: _ctx.subject.length == 0,
        "no-caps": "",
        label: _ctx.$t('message.send_notification'),
        color: "primary",
        "text-color": "white",
        onClick: _ctx.onSubmit,
        style: {"float":"right","margin-top":"16px"}
      }, null, 8, ["disable", "label", "onClick"])
    ], 4)
  ]))
}