import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import { TUserAgent } from '@/types/TUserAgent';
import { TUserAgent2 } from '@/types/TUserAgent2';
import { TUserAgent3 } from '@/types/TUserAgent3';
import { TUserAgent4 } from '@/types/TUserAgent4';
import { TUserMonth } from '@/types/TUserMonth';
import { TUserMonthTimeSpent } from '@/types/TUserMonthTimeSpent';
import { TUserMonthOffers } from '@/types/TUserMonthOffers';
import { TUserMonthCategories } from '@/types/TUserMonthCategories';
import { TUserMonthOffersCount } from '@/types/TUserMonthOffersCount';
import { TUserAgentOffersTimeBetween } from '@/types/TUserAgentOffersTimeBetween';

interface TAjaxResponseLogUpdateTimeSpentPerPage {
    status: string;
    message: string;
    appid: number;
}

interface TAjaxResponseUsers {
    status: string;
    message: string;
    agents: TUserAgent[];
}

interface TAjaxResponseUsers2 {
    status: string;
    message: string;
    agents: TUserAgent2[];
}

interface TAjaxResponseUsers3 {
    status: string;
    message: string;
    agents: TUserAgent3[];
}

interface TAjaxResponseUserAgentOffersTimeBetween {
    status: string;
    message: string;
    agents: TUserAgentOffersTimeBetween[];
}

interface TAjaxResponseUsersMonths {
    status: string;
    message: string;
    months: TUserMonth[];
}

interface TAjaxResponseUsersMonthsTimeSpent {
    status: string;
    message: string;
    months: TUserMonthTimeSpent[];
}

interface TAjaxResponseUsersMonthsOffers {
    status: string;
    message: string;
    months: TUserMonthOffers[];
}

interface TAjaxResponseUsersMonthsOffersCount {
    status: string;
    message: string;
    months: TUserMonthOffersCount[];
}

interface TAjaxResponseUsersMonthsCategories {
    status: string;
    message: string;
    months: TUserMonthCategories[];
}

export default class ServiceLogs {
    public static async updateTimeSpentPerPage(page:string, id_subcategory: string, appid: number): Promise<TAjaxResponseLogUpdateTimeSpentPerPage> {
        const params = new URLSearchParams();
        params.set('page', page);
        params.set('id_subcategory', id_subcategory);
        const response = await axios.post(`${CONFIG_ENV.URL_LOGS.updateTimeSpentPerPage}/${appid}`,params);
        return response.data;
    }

    public static async getUsersNumberLogsPerAgent(startDate: string, endDate: string): Promise<TAjaxResponseUsersMonths>{ 
        const params = new URLSearchParams();
        params.set('startDate', startDate);
        params.set('endDate', endDate);
        const response = await axios.post(`${CONFIG_ENV.URL_LOGS.getUsersNumberLogsPerAgent}`, params);
        return response.data;

    }

    public static async getCategoriesDetailsReport(startDate: string, endDate: string): Promise<TAjaxResponseUsersMonthsCategories>{
        const params = new URLSearchParams();
        params.set('startDate', startDate);
        params.set('endDate', endDate);
        const response = await axios.post(`${CONFIG_ENV.URL_LOGS.getCategoriesDetailsReport}`, params);
        return response.data;

    }

    public static async getCategoriesDetailsReportQuantity(startDate: string, endDate: string): Promise<TAjaxResponseUsersMonthsCategories>{
        const params = new URLSearchParams();
        params.set('startDate', startDate);
        params.set('endDate', endDate);
        const response = await axios.post(`${CONFIG_ENV.URL_LOGS.getCategoriesDetailsReportQuantity}`, params);
        return response.data;

    }

    public static async getCategoriesDetailsReportClick(startDate: string, endDate: string): Promise<TAjaxResponseUsersMonthsCategories>{
        const params = new URLSearchParams();
        params.set('startDate', startDate);
        params.set('endDate', endDate);
        const response = await axios.post(`${CONFIG_ENV.URL_LOGS.getCategoriesDetailsReportClicks}`, params);
        return response.data;

    }

    public static async getOffersDetailsReport(startDate: string, endDate: string): Promise<TAjaxResponseUsersMonthsOffers>{
        const params = new URLSearchParams();
        params.set('startDate', startDate);
        params.set('endDate', endDate);
        const response = await axios.post(`${CONFIG_ENV.URL_LOGS.getOffersDetailsReport}`, params);
        return response.data;

    }

    public static async getOffersDetailsReportCount(startDate: string, endDate: string): Promise<TAjaxResponseUsersMonthsOffersCount>{
        const params = new URLSearchParams();
        params.set('startDate', startDate);
        params.set('endDate', endDate);
        const response = await axios.post(`${CONFIG_ENV.URL_LOGS.getOffersDetailsReportCount}`, params);
        return response.data;

    }

    public static async getOffersDetailsReportQuantity(startDate: string, endDate: string): Promise<TAjaxResponseUsersMonthsOffers>{
        const params = new URLSearchParams();
        params.set('startDate', startDate);
        params.set('endDate', endDate);
        const response = await axios.post(`${CONFIG_ENV.URL_LOGS.getOffersDetailsReportQuantity}`, params);
        return response.data;

    }

    public static async getOffersDetailsReportTimeBetween(startDate: string, endDate: string): Promise<TAjaxResponseUserAgentOffersTimeBetween>{
        const params = new URLSearchParams();
        params.set('startDate', startDate);
        params.set('endDate', endDate);
        const response = await axios.post(`${CONFIG_ENV.URL_LOGS.getOffersDetailsReportTimeBetween}`, params);
        return response.data;

    }

    public static async getBasketReport(): Promise<TAjaxResponseUsers3>{
        const response = await axios.get(`${CONFIG_ENV.URL_LOGS.getBasketReport}`);
        return response.data;

    }

    public static async getUsersTimeSpent(startDate: string, endDate: string): Promise<TAjaxResponseUsersMonthsTimeSpent>{
        const params = new URLSearchParams();
        params.set('startDate', startDate);
        params.set('endDate', endDate);
        const response = await axios.post(`${CONFIG_ENV.URL_LOGS.getUsersTimeSpent}`, params);
        return response.data;

    }
    
    public static async getUsersActiveInactiveLastMonth(): Promise<TAjaxResponseUsersMonths>{ 
        const response = await axios.post(`${CONFIG_ENV.URL_LOGS.getUsersActiveInactiveLastMonth}`);
        return response.data;

    }
}
