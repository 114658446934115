import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c07b9b7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding-left":"3vw","padding-right":"3vw","padding-top":"2vw"} }
const _hoisted_2 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_3 = {
  key: 0,
  class: "app__page__title__container",
  style: {"display":"flex","align-items":"center"}
}
const _hoisted_4 = {
  class: "app__page__title",
  style: {"white-space":"nowrap","margin-left":"8px"}
}
const _hoisted_5 = { style: {"display":"flex","flex-direction":"column","gap":"16px"} }
const _hoisted_6 = { style: {"display":"flex","flex-direction":"column","min-height":"22vw","max-height":"22vw"} }
const _hoisted_7 = {
  class: "app__property--medium",
  style: {"font-weight":"bold","color":"black"}
}
const _hoisted_8 = {
  key: 0,
  style: {"display":"flex","justify-content":"center","margin-top":"1rem","align-items":"center"}
}
const _hoisted_9 = { style: {"display":"flex","flex-direction":"column","min-height":"22vw","max-height":"22vw"} }
const _hoisted_10 = ["loader"]
const _hoisted_11 = {
  class: "app__property--medium",
  style: {"font-weight":"bold","color":"black"}
}
const _hoisted_12 = {
  key: 0,
  style: {"display":"flex","justify-content":"center","margin-top":"1rem","align-items":"center"}
}
const _hoisted_13 = { style: {"display":"flex","flex-direction":"column","min-height":"22vw","max-height":"22vw"} }
const _hoisted_14 = ["loader"]
const _hoisted_15 = {
  class: "app__property--medium",
  style: {"font-weight":"bold","color":"black"}
}
const _hoisted_16 = {
  key: 0,
  style: {"display":"flex","justify-content":"center","margin-top":"1rem","align-items":"center"}
}
const _hoisted_17 = { style: {"display":"flex","flex-direction":"column","min-height":"22vw","max-height":"22vw"} }
const _hoisted_18 = ["loader"]
const _hoisted_19 = {
  class: "app__property--medium",
  style: {"font-weight":"bold","color":"black"}
}
const _hoisted_20 = {
  key: 0,
  style: {"display":"flex","justify-content":"center","margin-top":"1rem","align-items":"center"}
}
const _hoisted_21 = { style: {"display":"flex","flex-direction":"column","min-height":"22vw","max-height":"22vw"} }
const _hoisted_22 = {
  class: "app__property--medium",
  style: {"font-weight":"bold","color":"black"}
}
const _hoisted_23 = {
  key: 0,
  style: {"display":"flex","justify-content":"center","margin-top":"1rem","align-items":"center"}
}
const _hoisted_24 = { style: {"display":"flex","flex-direction":"column","min-height":"22vw","max-height":"22vw"} }
const _hoisted_25 = {
  class: "app__property--medium",
  style: {"font-weight":"bold","color":"black"}
}
const _hoisted_26 = {
  key: 0,
  style: {"display":"flex","justify-content":"center","margin-top":"1rem","align-items":"center"}
}
const _hoisted_27 = { style: {"display":"flex","flex-direction":"column","min-height":"22vw","max-height":"22vw"} }
const _hoisted_28 = {
  class: "app__property--medium",
  style: {"font-weight":"bold","color":"black"}
}
const _hoisted_29 = {
  key: 0,
  style: {"display":"flex","justify-content":"center","margin-top":"1rem","align-items":"center"}
}
const _hoisted_30 = { style: {"display":"flex","flex-direction":"column","min-height":"22vw","max-height":"22vw"} }
const _hoisted_31 = {
  class: "app__property--medium",
  style: {"font-weight":"bold","color":"black"}
}
const _hoisted_32 = {
  key: 0,
  style: {"display":"flex","justify-content":"center","margin-top":"1rem","align-items":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_Line = _resolveComponent("Line")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$q.platform.is.desktop)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_q_icon, {
              name: "summarize",
              style: {"font-size":"1.5rem"},
              color: "primary"
            }),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('message.reports')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          style: {"display":"flex","flex-direction":"space-between","align-items":"center","cursor":"pointer"},
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDetails('usersLogs', 'inactiveUsers')))
        }, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('message.users_evolution')), 1),
          _createVNode(_component_q_icon, {
            name: "arrow_forward_ios",
            style: {"margin-left":"36px"},
            color: "black"
          })
        ]),
        (_ctx.loadingUsersEvolution)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_q_spinner, {
                color: "primary",
                size: "3rem"
              })
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loadingUsersEvolution)
          ? (_openBlock(), _createBlock(_component_Line, {
              key: 1,
              chartData: _ctx.chartData,
              options: _ctx.options,
              style: {"cursor":"pointer","min-height":"22vw","max-height":"22vw"},
              height: 80
            }, null, 8, ["chartData", "options"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", {
          style: {"display":"flex","flex-direction":"space-between","align-items":"center","cursor":"pointer"},
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openDetails('offer', 'countOffers'))),
          loader: _ctx.loadedData
        }, [
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('message.requests_sent')), 1),
          _createVNode(_component_q_icon, {
            name: "arrow_forward_ios",
            style: {"margin-left":"36px"},
            color: "black"
          })
        ], 8, _hoisted_10),
        (_ctx.loadingCountOffers)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(_component_q_spinner, {
                color: "primary",
                size: "3rem"
              })
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loadingCountOffers)
          ? (_openBlock(), _createBlock(_component_Line, {
              key: 1,
              chartData: _ctx.chartDataCountOffers,
              options: _ctx.options,
              style: {"cursor":"pointer","min-height":"22vw","max-height":"22vw"},
              height: 80
            }, null, 8, ["chartData", "options"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", {
          style: {"display":"flex","flex-direction":"space-between","align-items":"center","cursor":"pointer"},
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openDetails('offer', 'quantityOffers'))),
          loader: _ctx.loadedData
        }, [
          _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('message.offers_quantity_report')), 1),
          _createVNode(_component_q_icon, {
            name: "arrow_forward_ios",
            style: {"margin-left":"36px"},
            color: "black"
          })
        ], 8, _hoisted_14),
        (_ctx.loadingOffersQuantity)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createVNode(_component_q_spinner, {
                color: "primary",
                size: "3rem"
              })
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loadingOffersQuantity)
          ? (_openBlock(), _createBlock(_component_Line, {
              key: 1,
              chartData: _ctx.chartDataOffersQuantity,
              options: _ctx.options,
              style: {"cursor":"pointer","min-height":"22vw","max-height":"22vw"},
              height: 80
            }, null, 8, ["chartData", "options"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", {
          style: {"display":"flex","flex-direction":"space-between","align-items":"center","cursor":"pointer"},
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openDetails('offer', 'offersTime'))),
          loader: _ctx.loadedData
        }, [
          _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('message.avg_offers_time_reply')), 1),
          _createVNode(_component_q_icon, {
            name: "arrow_forward_ios",
            style: {"margin-left":"36px"},
            color: "black"
          })
        ], 8, _hoisted_18),
        (_ctx.loadingOffersTime)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
              _createVNode(_component_q_spinner, {
                color: "primary",
                size: "3rem"
              })
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loadingOffersTime)
          ? (_openBlock(), _createBlock(_component_Line, {
              key: 1,
              chartData: _ctx.chartDataOffersTimeBetween,
              options: _ctx.options,
              style: {"cursor":"pointer","min-height":"22vw","max-height":"22vw"},
              height: 80
            }, null, 8, ["chartData", "options"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", {
          style: {"display":"flex","flex-direction":"space-between","align-items":"center","cursor":"pointer"},
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openDetails('basket', 'basket')))
        }, [
          _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('message.users_with_products_in_basket')), 1),
          _createVNode(_component_q_icon, {
            name: "arrow_forward_ios",
            style: {"margin-left":"36px"},
            color: "black"
          })
        ]),
        (_ctx.loadingUsersWithProductsInBasket)
          ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
              _createVNode(_component_q_spinner, {
                color: "primary",
                size: "3rem"
              })
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loadingUsersWithProductsInBasket)
          ? (_openBlock(), _createBlock(_component_Line, {
              key: 1,
              chartData: _ctx.chartDataBasket,
              options: _ctx.options,
              style: {"cursor":"pointer","min-height":"22vw","max-height":"22vw"},
              height: 80
            }, null, 8, ["chartData", "options"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", {
          style: {"display":"flex","flex-direction":"space-between","align-items":"center","cursor":"pointer"},
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openDetails('usersTime', 'timeSpent')))
        }, [
          _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t('message.no_logins_and_time_spent')), 1),
          _createVNode(_component_q_icon, {
            name: "arrow_forward_ios",
            style: {"margin-left":"36px"},
            color: "black"
          })
        ]),
        (_ctx.loadingNoLoginsAndTimeSpent)
          ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
              _createVNode(_component_q_spinner, {
                color: "primary",
                size: "3rem"
              })
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loadingNoLoginsAndTimeSpent)
          ? (_openBlock(), _createBlock(_component_Line, {
              key: 1,
              chartData: _ctx.chartDataUsersTimeSpent,
              options: _ctx.options,
              style: {"cursor":"pointer","min-height":"22vw","max-height":"22vw"},
              height: 80
            }, null, 8, ["chartData", "options"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", {
          style: {"display":"flex","flex-direction":"space-between","align-items":"center","cursor":"pointer"},
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openDetails('offersQuantityCategories', 'offersQuantityCategories')))
        }, [
          _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t('message.tons_categories')), 1),
          _createVNode(_component_q_icon, {
            name: "arrow_forward_ios",
            style: {"margin-left":"36px"},
            color: "black"
          })
        ]),
        (_ctx.loadingOffersCategoriesQuantity)
          ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
              _createVNode(_component_q_spinner, {
                color: "primary",
                size: "3rem"
              })
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loadingOffersCategoriesQuantity)
          ? (_openBlock(), _createBlock(_component_Line, {
              key: 1,
              chartData: _ctx.chartDataCategoriesQuantity,
              options: _ctx.options,
              style: {"cursor":"pointer","min-height":"22vw","max-height":"22vw"},
              height: 80
            }, null, 8, ["chartData", "options"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("div", {
          style: {"display":"flex","flex-direction":"space-between","align-items":"center","cursor":"pointer"},
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openDetails('offersQuantityCategories', 'offersClicksCategories')))
        }, [
          _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$t('message.offers_categories_clicks')), 1),
          _createVNode(_component_q_icon, {
            name: "arrow_forward_ios",
            style: {"margin-left":"36px"},
            color: "black"
          })
        ]),
        (_ctx.loadingOffersCategoriesClicks)
          ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
              _createVNode(_component_q_spinner, {
                color: "primary",
                size: "3rem"
              })
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loadingOffersCategoriesClicks)
          ? (_openBlock(), _createBlock(_component_Line, {
              key: 1,
              chartData: _ctx.chartDataCategoriesClicks,
              options: _ctx.options,
              style: {"cursor":"pointer","min-height":"22vw","max-height":"22vw"},
              height: 80
            }, null, 8, ["chartData", "options"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}