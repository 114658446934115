import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52f10124"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "q-pa-sm ecran-container" }
const _hoisted_2 = {
  key: 0,
  style: {"display":"flex","align-items":"center","position":"relative"}
}
const _hoisted_3 = { class: "app__page__title__container" }
const _hoisted_4 = { style: {"white-space":"nowrap","font-size":"2rem"} }
const _hoisted_5 = { class: "dashboard__div__content" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 3,
  class: "app__title--small",
  style: {"position":"absolute","left":"50%","margin-left":"-100px"}
}
const _hoisted_8 = {
  key: 4,
  class: "app__back__bar--title",
  style: {"position":"absolute","left":"calc(50% - 100px)","top":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_bar = _resolveComponent("q-bar")!
  const _component_NomEditAdresaLivrare = _resolveComponent("NomEditAdresaLivrare")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$q.platform.is.desktop)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_q_icon, {
                name: "local_shipping",
                style: {"font-size":"2rem","margin-top":"-3px"},
                color: "primary"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('message.delivery_addresses')), 1)
            ])
          ]),
          (!_ctx.dialogAdresa)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 0,
                square: "",
                color: "blue",
                icon: "add",
                class: "adresa_livrare--add--btn z-top",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onOpenFormAddNewAdresa(null)))
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adrese, (adresa, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "dashboard__section",
        key: adresa.appid
      }, [
        _createElementVNode("div", {
          class: "dashboard__div__title",
          style: _normalizeStyle(adresa.isNewAddress == '1' ? 'color:#C7C7C7' : 'color:black')
        }, _toDisplayString(_ctx.$t('message.address2')) + " " + _toDisplayString(index+1) + " " + _toDisplayString(adresa.isNewAddress == '1' ? ' - ' + _ctx.$t('message.approval_address_sales') : ''), 5),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: "dashboard__div__parameter",
            onClick: ($event: any) => (_ctx.onOpenFormAdresa(adresa)),
            style: _normalizeStyle(adresa.isNewAddress == '1' ? 'color:#C7C7C7;' : 'color:black')
          }, _toDisplayString(adresa.adresaAdresa) + ", " + _toDisplayString(adresa.adresaLocalitate) + ", " + _toDisplayString(adresa.denJudet) + " " + _toDisplayString(adresa.codTara), 13, _hoisted_6)
        ])
      ]))
    }), 128)),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.dialogAdresa,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogAdresa) = $event)),
      persistent: "",
      maximized: true,
      "transition-show": _ctx.dialogTransitionShow,
      "transition-hide": _ctx.dialogTransitionHide
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, { class: "bg-white" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_bar, { class: "bg-white" }, {
              default: _withCtx(() => [
                (_ctx.$q.platform.is.desktop)
                  ? _withDirectives((_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      dense: "",
                      flat: "",
                      icon: "arrow_back",
                      color: "black"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, { "content-class": "bg-grey text-white" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('message.close')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 512)), [
                      [_directive_close_popup]
                    ])
                  : _createCommentVNode("", true),
                (_ctx.$q.platform.is.desktop)
                  ? (_openBlock(), _createBlock(_component_q_space, {
                      key: 1,
                      dense: ""
                    }))
                  : _createCommentVNode("", true),
                (_ctx.$q.platform.is.mobile)
                  ? _withDirectives((_openBlock(), _createBlock(_component_q_icon, {
                      key: 2,
                      name: "arrow_back_ios",
                      class: "app__arrow--back"
                    }, null, 512)), [
                      [_directive_close_popup]
                    ])
                  : _createCommentVNode("", true),
                (_ctx.$q.platform.is.desktop)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('message.delivery_address_details')), 1))
                  : _createCommentVNode("", true),
                (_ctx.$q.platform.is.mobile)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('message.delivery_address_details')), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_NomEditAdresaLivrare, {
                  propAdresa: _ctx.selectedAdresa,
                  closeHandler: _ctx.closeFormEditAdresaLivrare
                }, null, 8, ["propAdresa", "closeHandler"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "transition-show", "transition-hide"])
  ]))
}