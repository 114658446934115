import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import {CONFIG_ENV} from '@/config';
import {ServiceUser} from '@/services/ServiceUser';
import user from '@/store/user';
import offers from '@/store/offers';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TOffer} from "@/types/TOffer";
import ContacteleMeleColorMetal from "@/components/ContacteleMeleColorMetal.vue";
import HierarchicalChainBrowseHeader from "@/components/HierarchicalChainBrowseHeader/HierarchicalChainBrowseHeader.vue";
import { ServiceInvoice } from '@/services/ServiceInvoice';
import { ServiceAlerts } from '@/services/ServiceAlerts';
import { ServiceBalanta } from '@/services/ServiceBalanta';
import { ServiceMail } from '@/services/ServiceMail';
import ServiceLogs from '@/services/ServiceLogs';


@Options({
    name: "Dashboard",
    components: {ContacteleMeleColorMetal, HierarchicalChainBrowseHeader}
})
export default class Dashboard extends Vue {
    public inputSearchArticle='';
    declare public $refs: any;
    public userStore = getModule(user);
    public offersStore = getModule(offers);
    public overdueInvoiceCount = 0;
    public showAlertDepasireTermenFacturi=false;
    public showAlertCount=false;
    public appid = 0;

    get user(): TUser {
        return this.userStore.user;
    }

    get offers():TOffer[]{
        return this.offersStore.offers;
    }
    public closeAlertDepasireTermenFacturi(){
        this.showAlertDepasireTermenFacturi=false;
    }

    public onGoArticoleFavorite(){
        this.$router.push({name: 'ArticoleFavorite'});
    }

    public onGoCautaArticole(){
        this.$router.push({name: 'BrowseCategories',  params: { pid: '0' }});
    }

    public onGoToOffers(){
        this.$router.push({name: 'Offers'});
    }

    public onGoNomAdreseLivrare(){
        this.$router.push({name: 'AdreseLivrare'});
    }

    public onGoFacturi(){
        this.$router.push({name: 'Facturi'});
    }

    public onGoBalanta(){
        this.$router.push({name: 'Balanta'});
    }

    public onGoDateCompanie(){
        this.$router.push({name: 'DateCompanie'});
    }

    public openTehnipedia(){
        // window.open("https://color-metal.ro/ro/tehnipedia");
    }

    public openLink(link: string){
        window.open(link);
    }

    public onGoAlerte(){
        this.$router.push({name: 'Alerte'});
        this.overdueInvoiceCount = 0;
        this.showAlertCount = false;
    }

    public onGoUsersCompany(){
        this.$router.push({name: 'UsersCompany'});
    }

    public openContact(){
        this.$router.push({name: 'ContactFormular'});
    }
    public onGoDocumenteCompany(){
        this.$router.push({name: 'Documente'});
    }

    public activated(){
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showmenubar(true);
            this.userStore.set_page_transition('fade-in-left');
        }else{
            this.userStore.set_page_transition('fade-in-left');
        }
        const vueInst = this;
        
        vueInst.appid = 0;
        ServiceLogs.updateTimeSpentPerPage('dashboard', '0', vueInst.appid).then(responce => {
            if(responce.status == 'success'){
                vueInst.appid = responce.appid;
            }
        })
        window.addEventListener('beforeunload', function (event) {
            
            ServiceLogs.updateTimeSpentPerPage('dashboard', '0', vueInst.appid);
        });
    }

    public sendMail(){
        ServiceMail.sendMailNewOffer('275').then(response=>{
            console.log(response);
        });
    }

    public created() {
        console.log('dash created');
        ServiceInvoice.getInvoicesFromNAV();
    }

    public mounted() {
        console.log('dash mounted');
    }

    deactivated(): void {
        const vueInst=this;
        ServiceLogs.updateTimeSpentPerPage('dashboard', '0', vueInst.appid);
    }
}
